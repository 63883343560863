import Button from '@global/Button'
import dynamic from 'next/dynamic'
import { getSuburbs } from '@lib/api-services/suburbService'
import IconSearch from '@icons/Search'
import { useRouter } from 'next/router'
import { Router } from '@routes'
import { UrlBuilder } from '@lib/helpers'
import { useState } from 'react'
import * as S from './ProjectSearchForm.styled'
import IconClose from '@icons/Close'

const AsyncSelect = dynamic(() => import('react-select/async'), { ssr: false })

const ClearIndicator = (props) => {
  const { children = <IconClose style={{ width: 18 }} />, innerProps } = props
  return (
    <div {...innerProps} style={{ display: 'flex' }}>
      {children}
    </div>
  )
}

const ProjectSearchForm = () => {
  const [searchText, setSearchText] = useState('')
  const router = useRouter()

  const loadOptions = (q, callback) => {
    setSearchText(q)
    getSuburbs({ search: q, per_page: 100 }).then((resp) => {
      callback(
        resp.results.map(({ name }) => {
          return {
            value: name,
            label: name,
          }
        })
      )
    })
  }

  const onSelectSuburb = (option) => {
    const { query } = Router.router

    Router.pushRoute(
      UrlBuilder(`/${query.slug}/`, {
        address_suburb: option?.value || '',
        view: query.view || 'grid',
      }),
      { scroll: false }
    )
  }

  const defaultValue = router?.query?.address_suburb ?? ''

  return (
    <S.ProjectSearchForm>
      <S.SearchForm>
        <S.FormGroup>
          <IconSearch />
          <AsyncSelect
            value={
              defaultValue ? { label: defaultValue, value: defaultValue } : null
            }
            placeholder='Search by suburb'
            classNamePrefix='bp-autocomplete'
            loadOptions={loadOptions}
            noOptionsMessage={() =>
              searchText?.length < 3 ? null : 'No results found'
            }
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator: () => null,
              ClearIndicator,
            }}
            onChange={onSelectSuburb}
            isClearable
            onBlur={() => setSearchText('')}
          />
        </S.FormGroup>
        <Button
          color='secondary'
          size='big'
          onClick={() =>
            onSelectSuburb({ label: defaultValue, value: defaultValue })
          }
        >
          Search
        </Button>
      </S.SearchForm>
    </S.ProjectSearchForm>
  )
}

export default ProjectSearchForm
