import { UrlBuilder } from '../helpers'
import { apiCall } from '../apiServerHelper'
import { apis } from '../apiUrls'

export const getTestimonials = async params => {
  const url = UrlBuilder(apis.TESTIMONIAL_API_BASE, params)
  return apiCall(url)
}

export const getTestimonialDetail = id => {
  return apiCall(`${apis.TESTIMONIAL_API_BASE}${id}`)
}
