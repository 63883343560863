import React, { useState, useEffect } from 'react';
import { Link } from '@routes';
import IconAgent from '@icons/Agent';
import IconOfficeLocation from '@icons/OfficeLocation';
import * as G from '@styles/MobileNav.styled';
import IconAppraisal from '@icons/Appraisal';
import IconAccount from '@icons/Account';
import { getCookie } from '@cookies';

const NavItem = ({ href, icon, children, active = false }) => {


  const content = (
    <G.MobileNavItem active={active}>
      {icon}
      {children}
    </G.MobileNavItem>
  );

  return <Link route={href} passHref>{content}</Link>;
};

const MobileNavbar = ({ currentPath = null }) => {
  const token = getCookie('token');
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <G.MobileNav justifyBetween scrolled={scrolled}>
      <NavItem href='/real-estate-agents' icon={<IconAgent />} active={currentPath && currentPath === 'real-estate-agents'}>Agents</NavItem>
      <NavItem href='/offices' icon={<IconOfficeLocation />} active={currentPath && currentPath === 'offices'}>Locations</NavItem>
      <NavItem href='/request-an-appraisal' icon={<IconAppraisal />} active={currentPath && currentPath === 'request-an-appraisal'}>Appraisal</NavItem>
      <NavItem href={token ? '/my-account' : '/login'} icon={<IconAccount />} active={currentPath === 'my-account' || currentPath === 'login'}>Account</NavItem>
    </G.MobileNav>
  )
}

export default MobileNavbar;
