import dynamic from 'next/dynamic'
import { getThankYouPageData } from '../lib/api-services/appraisalService'
import { getCookie } from '@lib/cookies'
import MobileNavbar from '@global/MobileNavbar'
import React from 'react'

const AppraisalForm = dynamic(
  () => import('@global/Form/AppraisalForm/AppraisalFormMain'),
  { ssr: false }
)

const AppraisalRequestPage = ({
  title,
  content_blocks,
  latest_news,
  pre_form_text,
  wallpaper_image,
  take_me_to_field,
  referral_source,
  referral_channel,
  current_path,
  office_agent_slug,
  thank_you_page_path,
}) => {
  const token = getCookie('token')

  return (
    <>
      <AppraisalForm
        title={title}
        copy={pre_form_text}
        backgroundImage={wallpaper_image}
        latest_news={latest_news}
        content_blocks={content_blocks}
        thankyou={take_me_to_field}
        referral_source={referral_source}
        referral_channel={referral_channel}
        appraisal_path={current_path}
        office_agent_slug={office_agent_slug}
        thank_you_page_path={thank_you_page_path}
      />
      <MobileNavbar currentPath='request-an-appraisal' />
    </>
  )
}

AppraisalRequestPage.getInitialProps = async (ctx, pageData) => {
  const {
    template_choice,
    heading_colour,
    title,
    pre_form_text,
    wallpaper_image,
    image_position,
    take_me_to_links,
    content_blocks,
    latest_news,
    title_colour,
    referral_source,
    referral_channel,
  } = pageData

  let office_agent_slug = ctx.query.slug

  let thankYouPagePath = ''
  if (
    pageData &&
    pageData.thank_you_page &&
    pageData.thank_you_page?.meta?.detail_url
  ) {
    const thankYouPageData = await getThankYouPageData(
      pageData.thank_you_page.meta.detail_url
    )
    thankYouPagePath = thankYouPageData?.meta?.path || ''
  }
  let take_me_to_options = {
    type: 'select',
    value: 'Select from list',
    options: [
      {
        value: 'Select from list',
        label: 'Select from list',
      },
    ],
  }

  if (pageData && pageData.take_me_to_links && take_me_to_links.length > 0) {
    take_me_to_links.map((link) => {
      take_me_to_options.options.push({
        value: link.link.path,
        label: link.link.title,
      })
    })
  }
  const take_me_to_field = {
    // This sets up other parts of the form field.
    // We only want to override the options.
    title: 'Thank you',
    copy: 'One of our property experts will call you for a confidential discussion.',
    fieldLabel: 'Take me to: ',
    field: {
      type: 'select',
      options: [
        {
          value: 'Select from list',
          label: 'Select from list',
        },
        {
          value: 'item 1',
          label: 'item 1',
        },
        {
          value: 'item 2',
          label: 'item 2',
        },
      ],
    },
    ...{ field: take_me_to_options },
  }
  return {
    template_choice,
    heading_colour,
    title,
    pre_form_text,
    wallpaper_image: wallpaper_image.url,
    backgroundPosition: image_position,
    take_me_to_field,
    title_colour,
    content_blocks,
    latest_news,
    referral_source,
    referral_channel,
    current_path: pageData && pageData.meta ? pageData.meta.path : '',
    thank_you_page_path: thankYouPagePath,
    office_agent_slug: office_agent_slug,
  }
}

export default AppraisalRequestPage
