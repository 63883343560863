import React from 'react'
import WagtailBlocksSection from '@global/Blocks/WagtailBlocksSection'
import SuburbProfilesHero from '@components/SuburbProfiles/SuburbProfilesHero'

const LocalInformationLanding = (props) => {
  const {
    pageData: { title, sub_heading, intro, content_blocks },
  } = props

  return (
    <>
      <SuburbProfilesHero
        isRegion
        title={title}
        subTitle={sub_heading}
        content={intro}
      />
      <WagtailBlocksSection blocks={content_blocks} />
    </>
  )
}

export default LocalInformationLanding
