import { useSiteSettingsContext } from '@context/SiteSettingsContext'
import WagtailBlocksSection from '../components/global/Blocks/WagtailBlocksSection'
import CarrerDetails from '@components/Careers/CarrerDetails'
import Hero from '@global/Blocks/Hero'
import Tag from '@global/Tag'
import dynamic from 'next/dynamic'

const CareerApplyForm = dynamic(() => import('@global/Form/CarrerApplyForm'), {
  ssr: false,
})

const JOB_TYPE = {
  full: 'Full time',
  part: 'Part time',
  casual: 'Casual',
}

const CareerPage = ({ pageData }) => {
  const { settings } = useSiteSettingsContext()
  const heroSettingsData = settings?.contentsettings?.career_hero_panel?.[0]

  const {
    id,
    title,
    closing_date,
    get_job_type_display,
    job_type,
    salary_details,
    job_name,
    career_image,
    location,
    description,
  } = pageData
  return (
    <>
      <Hero
        carrer
        tag={<Tag tag={JOB_TYPE[job_type]} />}
        title={job_name}
        bgImageUrl={
          career_image?.meta?.download_url || heroSettingsData?.background_image
        }
        titlePosition='bottom'
      />
      <CarrerDetails description={description} office={location} />
      <CareerApplyForm id={id} jobName={job_name} office={location} />
      {pageData?.content_blocks && (
        <WagtailBlocksSection blocks={pageData.content_blocks} />
      )}
    </>
  )
}

export default CareerPage
