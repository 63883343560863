import { Container, Section } from '@styles/global.styled'
import RegionCard from '@components/SuburbProfiles/RegionList/RegionCard'
import * as S from './RegionList.styled'

const RegionList = ({ title, blocks = [] }) => {
  return (
    <Section>
      <Container>
        <S.RegionListTitle>{title}</S.RegionListTitle>
        <S.RegionList>
          {blocks?.map((region, key) => (
            <RegionCard key={`region-${key}`} region={region} />
          ))}
        </S.RegionList>
      </Container>
    </Section>
  )
}

RegionList.defaultProps = {
  title: 'Explore Suburbs by Region',
  blocks: [],
}
export default RegionList
