import { COLORS } from '@styles/colors'
import styled from 'styled-components'

export const Input = styled.input`
  border: 1px solid ${COLORS.grey[40]};
  border-radius: 4px;
  padding: 9px 16px;
  width: 100%;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.1px;

  &::placeholder {
    color: ${COLORS.grey[40]};
  }

  &:focus {
    outline: none;
  }
`
