import * as S from './BlogAuthor.styled'
import ContactCard from '@global/ContactCard'

const BlogAuthor = ({data}) => {

  const { name, subTitle, photo, url } = data

  return (
    <S.BlogAuthorWrap>
      <ContactCard
        image={photo}
        name={name}
        nameUrl={url}
        subTitle={subTitle}
        urlFullInfo
      />
    </S.BlogAuthorWrap>

  )
}
export default BlogAuthor

