import {
  apiCall
} from '../apiServerHelper'
import {
  apis
} from '../apiUrls'
import {
  UrlBuilder
} from '../helpers'

export const getAgents = options => {
  return apiCall(UrlBuilder(apis.AGENTS, options))
}

export const getAgent = id => {
  return apiCall(`${apis.AGENTS}/${id}/`)
}

export const getAgentBySlug = async slug => {
  return await apiCall(`${apis.AGENTS}${slug}/`)
}

export const agentEnquiryForm = data => {
  return apiCall(apis.AGENT_ENQUIRY, data, "POST", false);
}
