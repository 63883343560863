import React from 'react'
import HeroSection from '../components/global/Blocks/Hero'
import BasicText from '../components/global/Blocks/FullWidthText'
import MediaHubNav from '../components/global/media-hub-nav/MediaHubNav'
import WagtailBlocksSection from '../components/global/Blocks/WagtailBlocksSection'
import { getChildPages } from '@lib/api-services/cmsService'
import FullWidthVideo from '../components/global/Blocks/VideoFeature'
import { itemShowPerPage, apiFieldsQuery } from '@lib/variables'
import dynamic from 'next/dynamic'

const CustomLoader = dynamic(() => import('../components/global/Spinner'), {
  ssr: false,
})

const MediaHubFilterForm = dynamic(
  () => import('@components/MediaHub/MediaHubFilterForm/MediaHubFilterForm'),
  { ssr: false }
)

class BarryPlantTVIndexPage extends React.Component {
  constructor(props) {
    super(props)
    const { items, meta } = props
    this.state = {
      items: items || [],
      page: 1,
      totalRecords: (meta && meta.total_count) || 1,
      totalPages:
        Math.ceil(meta.total_count / itemShowPerPage.BarryPlantTVIndexPage) ||
        1,
      loading: false,
      values: {},
      scrollTo: 0,
    }

    this.resultsRef = null
  }

  filterSubmitHandle = (values) => {
    this.setState({ values: values }, this.getDataItems)
  }

  updatePage = (page) => {
    this.setState({ page: page }, this.getDataItems)
  }

  getDataItems = () => {
    const { pageData, office } = this.props
    this.setState({ loading: true })

    // Determine if we are getting children of the current
    // page OR objects relevant to the current Office site.
    let child_of = pageData.id
    let for_office = null
    if (office) {
      child_of = null
      for_office = office.id
    }
    getChildPages(
      'media_hub.BPTVVideo',
      child_of,
      itemShowPerPage.BarryPlantTVIndexPage,
      this.state.page,
      { ...this.state.values, order: '-go_live_at', for_office: for_office },
      apiFieldsQuery.bptvVideo
    ).then((resp) => {
      this.setState({ loading: false })
      if (resp.items) {
        this.setState({
          items: resp.items.map((item) => {
            return {
              title: item.title,
              posterUrl:
                (item.cover_image_results && item.cover_image_results.url) ||
                '/static/img/defaults/default-medium__video.jpg',
              videoUrl: item.video_url,
              caption: item.title,
            }
          }),
          meta: resp.meta,
          totalRecords: (resp.meta && resp.meta.total_count) || 1,
          totalPages:
            Math.ceil(
              resp.meta.total_count / itemShowPerPage.BarryPlantTVIndexPage
            ) || 1,
        })
      }
    })
  }

  componentDidMount() {
    this.setState({
      scrollTo: this.resultsRef && this.resultsRef.offsetTop,
    })
  }

  render() {
    const { pageData } = this.props
    const { items, page, totalRecords, scrollTo } = this.state
    const {
      hero_text,
      hero_image,
      hero_image_anchor,
      intro_text,
      content_blocks,
    } = pageData
    return (
      <>
        {hero_image && hero_image.url ? (
          <HeroSection
            title={hero_text || ''}
            bgImageUrl={hero_image.url || ''}
            bgPosition={hero_image_anchor}
            titlePosition='bottom'
          >
            <MediaHubNav {...pageData} />
          </HeroSection>
        ) : (
          <div className='block-padding' />
        )}
        {intro_text && (
          <BasicText
            className='block-padding'
            para={intro_text}
            title=''
            link=''
          />
        )}
        <div ref={(el) => (this.resultsRef = el)} />
        <MediaHubFilterForm
          onSubmit={this.filterSubmitHandle}
          changeOrder={this.filterSubmitHandle}
          arrangeByField={{
            type: 'select',
            name: 'order',
            fieldSize: 'u-1/2 u-1/4@tablet u-width-15@xwide',
            options: [
              {
                value: 'id',
                label: 'Latest',
                selected: true,
              },
              {
                value: 'title',
                label: 'Title',
                selected: true,
              },
            ],
          }}
          order_by='id'
        />
        <div style={{ position: 'relative' }}>
          <div className='o-wrapper block-component'>
            <div className='o-layout'>
              {items &&
                items.map((item, key) => (
                  <div
                    key={`media-hub-item-${key}`}
                    className='o-layout__item  u-1/2@tablet o-island u-margin-bottom u-margin-top'
                  >
                    <div className='c-barryplant-tv-block__video-wrap'>
                      <FullWidthVideo {...item} noWrap />
                      <div className='c-barryplant-tv-block__video-caption'>
                        <h4>
                          {item.caption
                            ? item.caption
                            : item.title
                            ? item.title
                            : ''}
                        </h4>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          {this.state.loading && <CustomLoader />}
        </div>
        <WagtailBlocksSection blocks={content_blocks} />
      </>
    )
  }
}
BarryPlantTVIndexPage.getInitialProps = async (ctx, pageData) => {
  // Determine if we are getting children of the current
  // page OR objects relevant to the current Office site.
  let child_of = pageData.id
  let for_office = null
  if (pageData.meta.office_ancestor) {
    child_of = null
    for_office = pageData.meta.office_ancestor.id
  }

  let childrenPages = await getChildPages(
    'media_hub.BPTVVideo',
    child_of,
    itemShowPerPage.BarryPlantTVIndexPage,
    1,
    { order: '-go_live_at', for_office: for_office },
    apiFieldsQuery.bptvVideo
  )
  return {
    items: childrenPages.items.map((item) => {
      return {
        title: item.title,
        posterUrl:
          (item.cover_image_results && item.cover_image_results.url) ||
          '/static/img/defaults/default-medium__video.jpg',
        videoUrl: item.video_url,
        caption: item.title,
      }
    }),
    meta: childrenPages.meta,
    office: pageData.meta.office_ancestor,
  }
}
export default BarryPlantTVIndexPage
