import React, { useEffect, useState } from 'react'
import { POPUP_COOKIE, getCookie, setCookie } from '@lib/cookies'
import IconClose from '@icons/Close'
import * as S from './AnnouncementBar.styled'

const AnnouncementBar = ({ content, cookie }) => {
  const [show, setShow] = useState(false)

  const handleShowFromCookie = () => {
    if (!show && content) {
      const popup_close = getCookie(cookie || POPUP_COOKIE)
      if (popup_close == 'true') {
        setShow(false)
      } else {
        setShow(true)
      }
    }
  }

  useEffect(() => {
    handleShowFromCookie()
  }, [show, cookie])

  const close = () => {
    setShow(false)
    setCookie(cookie || POPUP_COOKIE, true)
  }

  return show ? (
    <S.AnnouncementBar id='announcement'>
      <S.AnnouncementBarContent dangerouslySetInnerHTML={{ __html: content }} />
      <IconClose onClick={close} />
    </S.AnnouncementBar>
  ) : null
}

AnnouncementBar.defaultProps = {
  open: false,
}

export default AnnouncementBar
