import React, { useContext } from 'react'
import {
  availableDate,
  titleCase,
  filterHrefs,
  getUpperPrice,
  getLowerPrice,
  isExternalURL,
  getPropertyType,
  getOutCome,
} from '@lib/helpers'
import ListingPrice from '../ListingPrice'
import ListingTags from '../ListingTags'
import { defaultImages } from '@lib/variables'
import { logEvent } from '@lib/analyticsService'
import { getCookie, ACTIVEPIPE_COOKIE } from '@lib/cookies'
import { UserContext } from '@context/UserContext'
import OffMarketOverlay from '../OffMarketOverlay'
import { Link, Router } from '@routes'
import FavouriteHeart from '@global/FavouriteHeart'
import IconBed from '@icons/Bed'
import IconBath from '@icons/Bath'
import IconCar from '@icons/Car'
import Image from '@global/Image'
import { Col, Row } from '@styles/global.styled'
import ListingCardInspections from './ListingCardInspections'
import ListingCardAuctions from './ListingCardAuctions'
import * as S from './ListingCardHorizontal.styled'

const ListingCardHorizontal = ({
  dataSource,
  isFeatured,
  hideTags,
  showType,
  quickload,
  isAuctionView,
}) => {
  const { token } = useContext(UserContext)
  const apCookie = getCookie(ACTIVEPIPE_COOKIE)

  // GA: log property view in Google Analytics
  logEvent(
    'Property',
    dataSource.address + ' - ' + dataSource.id,
    'Search Results',
    1
  )

  return (
    <S.ListingCard
      data-gtm-property-id={dataSource.id}
      data-gtm-office-name={
        (dataSource.office && dataSource.office.title) || 'Barry Plant'
      }
      data-gtm-office-id={dataSource.office && dataSource.office.id}
      data-gtm-created={dataSource.created}
      data-gtm-modified={dataSource.updated}
      data-gtm-agent-id={
        (dataSource.agent_ids &&
          dataSource.agent_ids.length > 0 &&
          dataSource.agent_ids.join()) ||
        ''
      }
      data-gtm-property-type={getPropertyType(dataSource, showType)}
      data-gtm-property-outcome={getOutCome(dataSource, showType)}
      data-gtm-property-price-upper={
        getUpperPrice(dataSource, showType)
          ? getUpperPrice(dataSource, showType)
          : getLowerPrice(dataSource, showType)
      }
      data-gtm-property-price-lower={getLowerPrice(dataSource, showType)}
      data-gtm-property-address={`${dataSource.address}${
        dataSource.address_postcode && ', ' + dataSource.address_postcode
      }`}
    >
      <span hidden>{(dataSource && dataSource.uniqueID) || ''}</span>
      <S.ListingCardImage>
        <Link
          route={dataSource.url}
          passHref
          prefetch={false}
          target={isExternalURL(dataSource.url) ? '_blank' : '_self'}
        >
          <Image
            quickload={quickload}
            imgMobile={dataSource.image_mobile_url}
            src={dataSource.image_url || defaultImages.mediumNavy}
            fallbackImage={defaultImages.mediumNavy}
            alt={dataSource.address || ''}
          />
        </Link>
        {!dataSource.offmarket && (
          <FavouriteHeart type='listing' id={dataSource.id} />
        )}
        {!hideTags && (
          <ListingTags listing={dataSource} isFeatured={isFeatured} />
        )}
        {dataSource.offmarket && (
          <OffMarketOverlay
            token={token}
            listingId={dataSource.id}
            returnName={`Exclusive property in ${dataSource.address}`}
            returnPath={dataSource.url}
            isResults
          />
        )}
      </S.ListingCardImage>
      <S.ListingCardContent>
        <S.ListingCardAddress>
          <Link
            route={dataSource.url}
            passHref
            prefetch={false}
            target={isExternalURL(dataSource.url) ? '_blank' : '_self'}
          >
            {dataSource.offmarket && !token && !apCookie ? (
              <React.Fragment>
                <span>Unlock to view address</span>
                <br />
                <span>
                  <strong>{dataSource.address}</strong>{' '}
                  {dataSource.address_postcode}
                </span>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <span>
                  {dataSource.address_display
                    ? dataSource.address_street_display
                    : 'Contact agent for full address'}
                </span>
                <br />
                <span>
                  <strong>{dataSource.address_suburb}</strong>{' '}
                  {dataSource.address_postcode}
                </span>
              </React.Fragment>
            )}
          </Link>
        </S.ListingCardAddress>

        <Row className='listing_group'>
          <Col md={5}>
            <S.ListingCardFeatures>
              {dataSource.features?.map((item, index) => (
                <S.ListingCardFeature key={index}>
                  <S.ListingCardFeatureLabel>
                    {item.label}
                  </S.ListingCardFeatureLabel>
                  {item.icon}
                </S.ListingCardFeature>
              ))}
            </S.ListingCardFeatures>
          </Col>
          <Col md={7}>
            <S.ListingCardPrice>
              <ListingPrice listing={dataSource} />
            </S.ListingCardPrice>
          </Col>
        </Row>

        {isAuctionView ? (
          <ListingCardAuctions
            auction={dataSource.auction_date}
            inspection={dataSource.inspection?.inspection_time}
            timezone={dataSource.timezone}
          />
        ) : (
          <ListingCardInspections
            inspection={dataSource.inspection}
            timezone={dataSource.timezone}
            inspections={dataSource.inspections}
          />
        )}
      </S.ListingCardContent>
    </S.ListingCard>
  )
}

ListingCardHorizontal.defaultProps = {
  dataSource: {},
  isNew: false,
  isOpen: false,
}

ListingCardHorizontal.ApiDataToProps = (data, isListingRow = false) => {
  // Converts API data into the component props
  let dataSource = {}
  dataSource.id = data.id
  dataSource.address_display = data.address_display
  dataSource.address = data.address_street_display
    ? `${data.address_street_display.trim()}`
    : data.address_suburb
  dataSource.priceGuide = data.priceView || data.price || 'Contact Agent'
  dataSource.features = []
  if (data.bedrooms) {
    dataSource.features.push({ icon: <IconBed />, label: data.bedrooms })
  } else if (isListingRow) {
    dataSource.features.push({ icon: <IconBed />, label: 0 })
  }
  if (data.bathrooms) {
    dataSource.features.push({ icon: <IconBath />, label: data.bathrooms })
  } else if (isListingRow) {
    dataSource.features.push({ icon: <IconBath />, label: 0 })
  }
  if (data.parking) {
    dataSource.features.push({ icon: <IconCar />, label: data.parking })
  } else if (isListingRow) {
    dataSource.features.push({ icon: <IconCar />, label: 0 })
  }
  dataSource.image_url =
    data.images && data.images.length && data.images[0].image.image_800_600
  dataSource.image_mobile_url =
    data.images && data.images.length && data.images[0].image.image_320_240

  dataSource.url = data.get_absolute_url
  dataSource.address_postcode = data.address_postcode
  dataSource.auction_date = data.auction_date
  dataSource.status = data.status
  dataSource.soldDetails_price = data.soldDetails_price
  dataSource.soldDetails_price_display = data.soldDetails_price_display
  dataSource.soldDetails_date = data.soldDetails_date
  dataSource.listingType = data.listing_type
  dataSource.salebySetDate = data.salebySetDate
  dataSource.underOffer = data.underOffer
  dataSource.headline = data.headline
  dataSource.address_street_display = data.address_street_display
    ? data.address_street_display.trim()
    : ''
  dataSource.address_postcode = data.address_postcode
  dataSource.address_suburb = data.address_suburb
  dataSource.priceView = data.priceView
  dataSource.price_display = data.price_display
  dataSource.offmarket_status = data.offmarket_status
  dataSource.category = data.category
  dataSource.offmarket =
    data.status === 'offmarket' &&
    data.offmarket_status &&
    (data.offmarket_status === 'current' || data.offmarket_status === 'sold')
      ? true
      : false

  // Turn suburb to title case for offmarket listings
  if (dataSource.offmarket) dataSource.address = titleCase(dataSource.address)

  // handle offmarket listing status
  if (data.status && data.status === 'offmarket' && data.offmarket_status) {
    dataSource.status = data.offmarket_status
  }

  // first inspection
  if (data.inspections && data.inspections.length > 0) {
    dataSource.inspection = data.inspections[0]
    dataSource.inspections = data.inspections
  } else {
    dataSource.inspection = null
  }

  // "New" tag is created < 7 days ago
  const createdDate = new Date(data.created)
  const ONE_WEEK = 60 * 60 * 24 * 7 * 1000 // Milliseconds
  if (new Date() - createdDate < ONE_WEEK) {
    dataSource.new = true
  }

  // listing available date
  if (data.listing_type == 'lease' && data.dateAvailable) {
    dataSource.dateAvailable = availableDate(data.dateAvailable)
  }

  // hrefs
  const hrefs = filterHrefs(data, 'onlineauctions')
  if (hrefs.onlineauctions) dataSource.onlineauctions = hrefs.onlineauctions

  dataSource.liveAuctionUrl = data.live_auction_url
  dataSource.liveAuctionRegisterUrl = data.live_auction_register_url

  // Data attributes
  dataSource.office = data.office
  dataSource.created = data.created
  dataSource.updated = data.updated
  dataSource.agent_ids = data.agent_ids
  dataSource.uniqueID = data.uniqueID

  return { dataSource: dataSource }
}

export default ListingCardHorizontal
