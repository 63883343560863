import styled from 'styled-components'


export const InvestorLandingFormWrap = styled.div`

`

export const Form = styled.div`

`
