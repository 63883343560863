import React from 'react'
import Thankyou from '@components/Thankyou'
import WagtailBlocksSection from '@global/Blocks/WagtailBlocksSection'
import Hero from '@global/Blocks/Hero'
import { Section } from '@styles/global.styled'

const ThankYouPage = (props) => {
  const { title, bgImageUrl, content_blocks = [] } = props
  return (
    <>
      <Hero title={title} bgImageUrl={bgImageUrl} titlePosition='bottom' />
      <Section withPaddingBottom={content_blocks.length === 0}>
        <Thankyou />
      </Section>
      <WagtailBlocksSection blocks={content_blocks} />
    </>
  )
}

ThankYouPage.getInitialProps = (ctx, pageData) => {
  const { take_me_to_links, wallpaper_image } = pageData

  let take_me_to_options = {
    type: 'select',
    value: 'Select from list',
    options: [
      {
        value: 'Select from list',
        label: 'Select from list',
      },
    ],
  }

  if (pageData && pageData.take_me_to_links && take_me_to_links.length > 0) {
    take_me_to_links.map((link) => {
      take_me_to_options.options.push({
        value: link.link.path,
        label: link.link.title,
      })
    })
  }

  const thankYouData = {
    // This sets up other parts of the form field.
    // We only want to override the options.
    key: 'thankyou',
    title: 'Thank you',
    text: 'One of our property experts will be in touch.',
    fieldLabel: 'Take me to: ',
    ...{ field: take_me_to_options },
  }

  return {
    title: 'Thank you for your appraisal request',
    bgImageUrl: wallpaper_image?.url || '/imgs/background-thankyou.jpeg',
    thankYouData,
    content_blocks: pageData.content_blocks,
  }
}

export default ThankYouPage
