import * as S from './NoResults.styled'
import Button from '@global/Button'

const NoResults = ({ onClearSearch }) => {

  return (
    <S.NoResultsWrap>
      <S.NoResultsText>
        Sorry, we couldn’t find any
        <br /> items matching your search.
      </S.NoResultsText>
      <Button color='primary' border onClick={onClearSearch}>
        Clear search
      </Button>
    </S.NoResultsWrap>
  )
}

export default NoResults
