import IconSearch from '@icons/Search'
import Input from '../Input'
import * as S from './SearchInput.styled'

const SearchInput = ({ onChange, placeholder, ...restProps }) => {
  return (
    <S.SearchInput>
      <IconSearch />
      <Input placeholder={placeholder} onChange={onChange} {...restProps} />
    </S.SearchInput>
  )
}

export default SearchInput
