import { Formik } from 'formik'
import Field from '@global/FormInput/Field'
import Button from '@global/Button'
import * as Yup from 'yup'
import dynamic from 'next/dynamic'
import { loadReCaptcha } from 'react-recaptcha-v3'
import { useEffect } from 'react'
import { ApiKeys } from '@lib/variables'
import { submitCMSForm } from '@lib/api-services/formsService'
import { Router } from '@routes'
import * as S from './InvestorLandingForm.styled'
import { Col, Row } from '@styles/global.styled'
import nProgress from 'nprogress'

const ReCaptcha = dynamic(
  () => import('react-recaptcha-v3').then((mod) => mod.ReCaptcha),
  {
    ssr: false,
  }
)

const validationSchema = Yup.object().shape({
  'first-name': Yup.string().required('First name is required'),
  'last-name': Yup.string().required('Last name is required'),
  'email-address': Yup.string()
    .required('Email Address is required')
    .email('Please enter a valid email address'),
})

const initialValues = {
  'email-address': '',
  'first-name': '',
  'last-name': '',
}

const InvestorLandingForm = ({ formSlug }) => {
  const handleSubmit = async (values, setSubmitting) => {
    nProgress.start()
    const res = await submitCMSForm(formSlug, values)
    if (res.ok) {
      Router.pushRoute(res.post_redirect_page)
      setSubmitting(false)
    } else {
      setSubmitting(false)
    }
    nProgress.done()
  }

  useEffect(() => {
    if (!window.RECAPTCHA_INITIALIZED) {
      loadReCaptcha(ApiKeys.RECAPTCHA_KEY)
      window.RECAPTCHA_INITIALIZED = true
    }
  }, [])

  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true)
        await handleSubmit(values, setSubmitting)
      }}
      initialValues={initialValues}
    >
      {({
        values,
        touched,
        errors,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        getFieldProps,
      }) => (
        <form
          style={{ position: 'relative' }}
          className='u-margin-bottom investor-landing-form'
          noValidate
          onSubmit={handleSubmit}
        >
          <ReCaptcha
            sitekey={ApiKeys.RECAPTCHA_KEY}
            action='enquire'
            verifyCallback={async (token) => {
              if (values['recaptcha'] !== token)
                setFieldValue('recaptcha', token, false)
            }}
          />
          <S.Form>
            <Row gutter={12}>
              <Col md={6}>
                <Field
                  label={'First Name'}
                  required
                  {...getFieldProps('first-name')}
                  error={touched['first-name'] && errors['first-name']}
                />
              </Col>
              <Col md={6}>
                <Field
                  label={'Last Name'}
                  required
                  {...getFieldProps('last-name')}
                  error={touched['last-name'] && errors['last-name']}
                />
              </Col>
            </Row>
            <Field
              label={'Email address'}
              required
              {...getFieldProps('email-address')}
              error={touched['email-address'] && errors['email-address']}
            />
            <Button type='submit' disabled={isSubmitting} color='primary'>
              GET THE GUIDE
            </Button>
          </S.Form>
        </form>
      )}
    </Formik>
  )
}

export default InvestorLandingForm
