import IconListView from '@icons/ListView'
import IconMapPoint from '@icons/MapPoint'
import * as S from './SearchResultsTabs.styled'

const TABS = [
  {
    label: (
      <span>
        Grid<span className='hidden-mb'>View</span>
      </span>
    ),
    value: 'grid',
    icon: <IconListView />,
  },
  {
    label: (
      <span>
        Map <span className='hidden-mb'>View</span>
      </span>
    ),
    value: 'map',
    icon: <IconMapPoint />,
  },
]

const SearchResultsTabs = ({ activeTab, onViewChange, disabled, children }) => {
  return (
    <S.SearchResultsTabs disabled={disabled}>
      <S.Tabs>
        {TABS.map((it) => (
          <S.Tab
            key={it.value}
            onClick={() => onViewChange(it.value)}
            active={activeTab === it.value}
          >
            {it.icon}
            {it.label}
          </S.Tab>
        ))}
      </S.Tabs>
      {children}
    </S.SearchResultsTabs>
  )
}

export default SearchResultsTabs
