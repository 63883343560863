import styled from 'styled-components'
import { COLORS } from '@styles/colors'
import { FONTS } from '@styles/fonts'
import { DEVICES } from '@constants/global'

export const SearchResultsCount = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 24px;
  color: ${COLORS.secondary};
  font-family: ${FONTS.medium};

  span:first-child {
    font-family: ${FONTS.regular};
  }

  > div {
    margin-left: auto;
  }

  @media ${DEVICES.mobile} {
    > div {
      margin-left: auto;
      display: block;
    }

    .main-select-container .react-select__control {
      border-color: ${COLORS.secondary};
      min-height: unset;
    }
    .main-select-container .react-select__input-container {
      margin: 0 2px;
    }
  }
`

export const SelectGroup = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  font-family: ${FONTS.regular};

  > span:first-child {
    margin-right: 20px;
    font-family: ${FONTS.medium};
  }

  > div {
    min-width: 202px;
  }
`
