import React, { useState } from 'react'
import AgentSearch from '@components/Agents/AgentSearchResults/AgentSearchForm'
import { getAgents } from '@lib/api-services/agentsService'
import { itemShowPerPage } from '@lib/variables'
import AgentCardGrid from '@components/Agents/AgentSearchResults/AgentCardGrid'
import { Container } from '@styles/global.styled'
import NoResults from '@global/SearchResults/NoResults'
import SearchResultsCount from '@global/SearchResults/SearchResultsCount'
import LoadmoreInfo from '@global/SearchResults/LoadmoreInfo'
import AgentOfficeHeading from '@components/Agents/AgentSearchResults/AgentOfficeHeading'
import MobileNavbar from '@global/MobileNavbar'

const buildDynamicFields = (meta, office, languages = []) => {
  let dynamicFields = []

  if (!office) {
    dynamicFields.push({
      name: 'offices__office__title',
      type: 'select-dropdown',
      label: 'Find by office',
      options:
        meta && meta.office_choices && meta.office_choices
          ? [{ value: '', label: 'Any' }].concat(
              meta.office_choices.map((opt) => {
                return {
                  value: opt.office__title,
                  label: opt.office__title,
                }
              })
            )
          : [],
    })
  }

  dynamicFields = dynamicFields.concat([
    {
      name: 'departments__department__name',
      type: 'select-dropdown',
      label: 'Find by department',
      options:
        meta && meta.department_choices && meta.department_choices
          ? [{ value: '', label: 'Any' }].concat(
              meta.department_choices.map((opt) => {
                return {
                  value: opt,
                  label: opt,
                }
              })
            )
          : [],
    },
    {
      name: 'categories__category__category_name',
      type: 'select-dropdown',
      label: 'Find by job/role',
      options:
        meta && meta.position_choices
          ? [{ value: '', label: 'Any' }].concat(
              meta.position_choices.map((opt) => {
                return {
                  value: opt,
                  label: opt,
                }
              })
            )
          : [],
    },
  ])

  dynamicFields = dynamicFields.concat({
    name: 'languages__language__name',
    type: 'select-dropdown',
    label: 'Find by language',
    options: languages.length
      ? [{ value: '', label: 'Any' }].concat(
          languages.map((opt) => {
            return {
              value: opt,
              label: opt,
            }
          })
        )
      : [],
  })
  return dynamicFields
}

const AgentIndexPage = ({
  meta,
  office,
  title,
  subTitle,
  h1Title,
  showH1AndText,
  initAgentsData,
  initLanguages,
}) => {
  const [languages, setLanguages] = useState(initLanguages ?? [])
  const [queryParams, setQueryParams] = useState({
    page: 1,
    per_page: itemShowPerPage.AgentIndexPage,
    offices__office__title: office?.title ?? '',
  })
  const [agents, setAgents] = useState({ ...initAgentsData })

  const getAgentList = async (params, isClearSearch) => {
    let nParams = { ...params }
    if (!isClearSearch) {
      nParams = { ...queryParams, ...params }
    }
    if (office) {
      nParams.offices__office__title = office.title
    }

    setQueryParams({ ...nParams })
    try {
      const res = await getAgents(nParams)
      setAgents({ ...res })

      if (nParams.languages__language__name) {
        const languagesRes = await getAgents({
          ...nParams,
          languages__language__name: '',
        })
        setLanguages(languagesRes.languages)
      } else {
        setLanguages(res.languages)
      }
    } catch {}
  }

  return (
    <>
      <Container>
        <AgentSearch
          dynamicFields={buildDynamicFields(meta, office, languages)}
          initialValues={queryParams}
          title={title}
          office={office}
          onSubmit={getAgentList}
        />

        {showH1AndText && (
          <AgentOfficeHeading title={h1Title} subTitle={subTitle} />
        )}

        <SearchResultsCount
          total={agents.count}
          numberShowing={queryParams.page * itemShowPerPage.AgentIndexPage}
        />
        {agents.count > 0 ? (
          <>
            <AgentCardGrid agents={agents.results} />
            <LoadmoreInfo
              total={agents.count}
              numberShowing={queryParams.page * itemShowPerPage.AgentIndexPage}
              queryParams={{
                ...queryParams,
                offices__office__title:
                  queryParams?.offices__office__title ?? office?.title ?? '',
              }}
              apiCall={getAgents}
              page={queryParams.page ?? 1}
              onSuccess={(data, newPage) => {
                const newResults = agents.results.concat(data)
                setAgents({
                  ...agents,
                  page: newPage,
                  results: newResults,
                })
                setQueryParams({
                  ...queryParams,
                  page: newPage,
                })
              }}
            />
          </>
        ) : (
          <NoResults
            title='Sorry, we couldn’t find any team members matching your search.'
            description='Try searching with another term, or extending your suburb reach.'
            onClearSearch={() => {
              getAgentList(
                {
                  page: 1,
                  per_page: itemShowPerPage.AgentIndexPage,
                  search: '',
                },
                true
              )
            }}
          />
        )}
      </Container>
      <MobileNavbar currentPath={'real-estate-agents'} />
    </>
  )
}

AgentIndexPage.getInitialProps = async (ctx, pageData) => {
  let query = { ...ctx.query }
  delete query.slug
  const queryParams = { ...query }

  if (pageData.meta.office_ancestor) {
    queryParams.offices__office__title = pageData.meta.office_ancestor.title
  }
  let agents = {}

  await getAgents({
    ...queryParams,
    page: 1,
    per_page: itemShowPerPage.AgentIndexPage,
  }).then((resp) => {
    if (resp.results) {
      agents = resp
    }
  })

  return {
    office: pageData.meta.office_ancestor,
    title: pageData.title,
    subTitle: pageData.subtitle,
    h1Title: pageData.h1_title,
    showH1AndText: pageData.show_h1_and_text,
    meta: pageData.results.meta,
    initAgentsData: agents,
    initLanguages: agents.languages,
  }
}
export default AgentIndexPage
