import styled from "styled-components";
import { DEVICES } from '@constants/global'

export const BlogPostWrap = styled.div`

  @media ${DEVICES.mobile} {
    .col-sidebar {
      display: none;
    }
  }
`

export const BlogPostNewsletterWrap = styled.div`
  display: none;
  @media ${DEVICES.mobile} {
    display: block;
  }
`

export const BlogPostRelatedPost = styled.div`
  > div {
    margin-bottom: 0;
  }
`

export const BlogPostSidebarNewsletterWrap = styled.div`
  position: sticky;
  top: 0;
  margin-top: 20px;
`
