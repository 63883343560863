import { Link } from '../../../routes'

const MediaHubNav = ({ meta, navigation_items }) => (
  <section className='c-mediahub-nav'>
    <div className='o-wrapper'>
      <ul className='c-secondary-nav'>
        {navigation_items &&
          navigation_items.length > 0 &&
          navigation_items.map(({ slug, url, title }, i) => (
            <li
              className='c-secondary-nav__item c-secondary-nav__item--plain'
              key={i}
            >
              <Link
                route={url}
                passHref
                className={`c-link c-link--plain${
                  meta.slug == slug ? ' is-active' : ''
                }`}
              >
                {title}
              </Link>
            </li>
          ))}
      </ul>
    </div>
  </section>
)

MediaHubNav.defaultProps = {
  navigation_items: [
    { slug: 'MediaHub', url: '/media-hub/', title: 'Media Hub' },
    { slug: 'news', url: '/media-hub/news/', title: 'News' },
    { slug: 'blog', url: '/media-hub/blog/', title: 'Blog' },
    { slug: 'resources', url: '/media-hub/resources/', title: 'Resources' },
  ],
  meta: {
    slug: 'MediaHub',
  },
}

export default MediaHubNav
