import { COLORS } from '@styles/colors'
import styled, { css } from 'styled-components'

export const SearchInput = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  min-height: 52px;
  color: ${COLORS.secondary};
  flex: 1;

  > svg {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
  }

  input {
    padding: 16px 20px 16px 56px;
    color: ${COLORS.secondary};
    border-color: ${COLORS.nature[80]};

    &::placeholder {
      color: ${COLORS.secondary};
    }
  }
`
