import * as S from './AgentOfficeHeading.styled'

const AgentOfficeHeading = ({ title, subTitle }) => {
  return title || subTitle ? (
    <S.AgentOfficeHeading>
      {title && <S.AgentOfficeTitle>{title}</S.AgentOfficeTitle>}
      {subTitle && <S.AgentOfficeSubTitle>{subTitle}</S.AgentOfficeSubTitle>}
    </S.AgentOfficeHeading>
  ) : null
}

export default AgentOfficeHeading
