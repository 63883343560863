const IconAppraisal = (props) => {
  return (
    <svg
      width='21'
      height='20'
      viewBox='0 0 21 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M7.83331 17.5V12.5C7.83331 12.058 8.00891 11.634 8.32147 11.3215C8.63403 11.0089 9.05795 10.8333 9.49998 10.8333H11.1666C11.6087 10.8333 12.0326 11.0089 12.3452 11.3215C12.6577 11.634 12.8333 12.058 12.8333 12.5V17.5M4.49998 10H2.83331L10.3333 2.5L17.8333 10H16.1666V15.8333C16.1666 16.2754 15.9911 16.6993 15.6785 17.0118C15.3659 17.3244 14.942 17.5 14.5 17.5H6.16665C5.72462 17.5 5.3007 17.3244 4.98813 17.0118C4.67557 16.6993 4.49998 16.2754 4.49998 15.8333V10Z'
        stroke='currentColor'
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default IconAppraisal
