import styled, { css } from 'styled-components'
import { COLORS } from '@styles/colors'
import { FONTS, textSM } from '@styles/fonts'
import { DEVICES } from '@constants/global'

export const ListingCardAuction = styled.div`
  color: ${COLORS.navi};

  label {
    ${textSM};
    font-family: ${FONTS.bold};
    letter-spacing: 1px;
    color: ${COLORS.primary};
    text-transform: uppercase;
    margin-bottom: 4px;
    display: block;
  }

  @media ${DEVICES.mobile} {
    ${({ primary }) =>
      primary &&
      css`
        color: ${COLORS.secondary};
      `}
  }
`
