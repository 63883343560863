import * as S from './BlogHero.styled'

const BlogHero = ({ imageUrl, maxHeight, imagePosition = 'top' }) => {
  return (
    <S.BlogHeroWrap
      bgImage={imageUrl}
      maxHeight={maxHeight}
      bgPosition={imagePosition}
    />
  )
}

export default BlogHero
