import styled, { css } from 'styled-components'
import { DEVICES } from '@constants/global'

export const BlogHeroWrap = styled.div`
  position: relative;
  border-radius: 0 0 80px 0;
  display: flex;
  align-items: center;
  overflow: hidden;
  background-image: url(${({ bgImage }) => bgImage});
  background-position: top center;
  background-size: cover;
  height: 100vh;

  ${({ bgPosition }) => {
    switch (bgPosition) {
      case 'top':
        return css`
          background-position: top center;
        `
      case 'center':
        return css`
          background-position: center center;
        `
      case 'bottom':
        return css`
          background-position: bottom center;
        `
      default:
        return css`
          background-position: top center;
        `
    }
  }}

  @media ${DEVICES.tablet} {
    height: calc(100vh - 62px);
  }

  @media ${DEVICES.mobile} {
    height: 358px;
    border-radius: 0 0 72px 0;
  }

  ${({ maxHeight }) =>
    maxHeight &&
    css`
      max-height: ${maxHeight};
    `}
`
