import styled from 'styled-components'
import { COLORS } from '@styles/colors'
import { FONTS, text2XL } from '@styles/fonts'

export const AgentOfficeHeading = styled.div`
  margin-bottom: 36px;
`

export const AgentOfficeTitle = styled.h2`
  ${text2XL};
  color: ${COLORS.secondary};
  font-family: ${FONTS.medium};
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 1.6px;
`

export const AgentOfficeSubTitle = styled.p``
