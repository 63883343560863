import SuburbProfilesHero from '@components/SuburbProfiles/SuburbProfilesHero'
import WagtailBlocksSection from '@global/Blocks/WagtailBlocksSection'
import { getChildPages } from '@lib/api-services/cmsService'
import { apiFieldsQuery } from '@lib/variables'
import TwinEqualCTA from '@global/Blocks/TwinEqualCTA'
import { Container, Section } from '@styles/global.styled'
import Breadcrumbs from '@global/Breadcrumbs'

const RegionPage = ({
  blocks,
  pageData: { title, hover_text, meta, content_blocks },
}) => {
  return (
    <>
      <Container>
        <Breadcrumbs
          breadcrumbs={[
            { label: 'Suburb Profiles', url: '/suburb-profile' },
            { label: meta.parent.title, url: meta.parent_path },
            { label: title, url: '#' },
          ]}
        />
      </Container>
      <SuburbProfilesHero title={title} content={hover_text} />
      <Section withPaddingBottom={!(content_blocks?.length > 0)}>
        <TwinEqualCTA
          title={blocks.title}
          titleStyle='normal'
          items={blocks.items}
        />
      </Section>
      {content_blocks?.length > 0 && (
        <WagtailBlocksSection blocks={content_blocks} />
      )}
    </>
  )
}

RegionPage.getInitialProps = async (ctx, pageData) => {
  let childrenPages = await getChildPages(
    'suburbs.SuburbPage',
    pageData.id,
    null,
    null,
    { limit: 100 },
    apiFieldsQuery.SuburbPage
  )

  return {
    blocks: {
      title: 'Explore Suburbs',
      items:
        childrenPages.items?.map((item) => {
          return {
            description: item.preamble,
            title: item.title,
            image: item?.featured_image_thumb?.url,
            url: item.meta.path,
          }
        }) ?? [],
    },
  }
}
export default RegionPage
