import React, { useState, useEffect } from 'react';
import IconAuctions from '@icons/Auctions'
import IconCalendar from '@icons/Clendar'
import IconAccount from '@icons/Account'
import { useUserContext } from '@context/UserContext'
import IconLockOutline from '@icons/LockOutline'
import IconSpect from '@icons/Inspect'
import { useRouter } from 'next/router'
import { Link } from '@routes'
import * as G from '@styles/MobileNav.styled'

const ResultsBarMobileBottomMenu = ({ onViewChange }) => {
  const { userProfile } = useUserContext()
  const routers = useRouter()
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <G.MobileNav justifyBetween scrolled={scrolled}>
      <G.MobileNavItem onClick={() => onViewChange('auctions')}>
        {routers?.asPath?.includes('auctions') ? (
          <>
            <IconCalendar />
            Timeable
          </>
        ) : (
          <>
            <IconAuctions />
            Auctions
          </>
        )}
      </G.MobileNavItem>
      <G.MobileNavItem onClick={() => onViewChange('inspections')}>
        {routers?.asPath?.includes('open-for-inspections') ? (
          <>
            <IconCalendar />
            Timeable
          </>
        ) : (
          <>
            <IconSpect />
            Inspections
          </>
        )}
      </G.MobileNavItem>
      <G.MobileNavItem onClick={() => onViewChange('exclusivepreview')}>
        <IconLockOutline />
        Off Market
      </G.MobileNavItem>
      <Link route={userProfile && userProfile.first_name ? '/my-account' : '/login'} passHref>
        <G.MobileNavItem>
          <IconAccount />
          Account
        </G.MobileNavItem>
      </Link>
    </G.MobileNav>
  )
}

export default ResultsBarMobileBottomMenu
