import styled from 'styled-components'
import { COLORS } from '@styles/colors'
import { DEVICES } from '@constants/global'
import { FONTS, text2XL, textLG } from '@styles/fonts'

export const CarrerDetails = styled.div`
  padding-right: 86px;

  h2 {
    text-transform: unset;
    font-family: ${FONTS.medium};
    margin-bottom: 40px;
  }

  @media ${DEVICES.tabletMini} {
    padding-right: 0;
    padding-bottom: 60px;
  }
`

export const CarrerDescription = styled.div`
  color: ${COLORS.navi};

  p, ul {
    padding-bottom: 16px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-transform: unset;
    font-family: ${FONTS.medium};
  }

  ul {
    padding-left: 20px;

    li {
      margin-bottom: 5px;
    }
  }

  h2,
  h3 {
    ${text2XL};
    letter-spacing: 0.1px;
  }

  h3 {
    padding-top: 48px;
    margin-bottom: 24px;
  }
`

export const CarrerOffice = styled.div`
  h4 {
    margin-bottom: 12px;
  }
`

export const CarrerActions = styled.div`
  padding-top: 26px;

  button {
    margin-bottom: 12px;
  }

  .share-button {
    display: block;
  }
`

export const RightInfo = styled.div`
  position: sticky;
  top: 20px;
`
