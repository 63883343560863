import styled from 'styled-components'
import { COLORS } from '@styles/colors'
import { DEVICES } from '@constants/global'
import { text4XL, text2XL } from '@styles/fonts'

export const OfficeSearchForm = styled.div`
  padding-top: 56px;

  @media ${DEVICES.mobile} {
    padding-top: 0;
  }
`

export const OfficeSearchFormHeading = styled.h1`
  ${text4XL};
  color: ${COLORS.secondary};
  margin-bottom: 32px;

  @media ${DEVICES.mobile} {
    ${text2XL};
    margin-bottom: 20px;
  }
`

export const OfficeSearchFormBody = styled.div`
  padding: 16px 24px;
  background-color: ${COLORS.nature[95]};
  display: flex;
  align-items: center;
  max-width: 830px;
  margin-bottom: 32px;

  button {
    margin-left: 28px;
    min-height: 42px;
  }

  @media ${DEVICES.mobile} {
    margin: 0px -15px 28px -15px;
  }
`
