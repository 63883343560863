import styled from 'styled-components'
import { COLORS } from '@styles/colors'
import { DEVICES } from '@constants/global'
import { FONTS } from '@styles/fonts'

export const SearchResultsForm = styled.div`
  padding-top: 52px;
  margin-bottom: 64px;
  display: flex;
  align-items: center;

  > div:first-child {
    flex: 0 0 50%;
    margin-right: 30px;
  }

  @media ${DEVICES.tabletMini} {
    padding-top: 16px;
    margin-bottom: 28px;

    > div:first-child {
      flex: 1;
      margin-right: 20px;
    }
  }
  
  @media ${DEVICES.mobile} {
    display: none;
  }
`

export const SearchResultsFormMobile = styled(SearchResultsForm)`
  display: none;
  @media ${DEVICES.mobile} {
    display: flex;
    gap:10px;
    align-items: unset;
    
    > div:first-child {
      margin-right: 0;

      .search-input {
        width: 100%;

        > svg {
          right: 20px;
          left: unset;
        }

        .bp-autocomplete {
          .bp-autocomplete__control {
            padding: 8px 50px 8px 8px;
          }
        }
      }
    }
    
    > div:last-child {
      justify-content: unset;
      margin-left: unset;
    }
  }
`

export const AutocompeteWrap = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-left: 16px;

    @media ${DEVICES.mobile} {
      display: none;
    }
  }
`

export const SelectedFilters = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;

  button {
    svg {
      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        display: none;
      }
    }
  }

  @media ${DEVICES.tabletMini} {
    button {
      background: none;
      padding-right: 0;
      color: ${COLORS.secondary};

      &:hover {
        background: none;
        color: ${COLORS.secondary};
        border-color: transparent;
      }

      svg {
        &:first-child {
          display: none;
        }

        &:last-child {
          display: inline-block;
        }
      }
    }
  }
`

export const SelectedFilterItem = styled.span`
  display: inline-flex;
  padding: 6px 14px;
  color: ${COLORS.secondary};
  background-color: ${COLORS.nature[95]};
  border-radius: 20px;
  margin-right: 10px;

  @media ${DEVICES.tabletMini} {
    display: none;
  }
`

export const NumberFilters = styled.span`
  display: none;

  @media ${DEVICES.tabletMini} {
    display: inline-block;
    background-color: ${COLORS.primary};
    font-family: ${FONTS.medium};
    color: ${COLORS.white};
    border-radius: 8px;
    padding: 3px 10px;
  }
`
