import { Col, Row } from '@styles/global.styled'
import Button from '@global/Button'
import Field from '@global/FormInput/Field'
import * as S from './AgentFiltersForm.styled'

const AgentFiltersForm = ({ open, dynamicFields, onChange, values }) => {
  return (
    <S.AgentFiltersForm open={open}>
      <Row gutter={15} mbGutter={8}>
        {dynamicFields?.map(
          (field) =>
            field.options.length > 0 && (
              <Col md={3} sm={6} key={field.name}>
                <Field
                  type='select'
                  value={values[field.name]}
                  label={field.label}
                  options={
                    field.name === 'languages__language__name'
                      ? field.options.sort((a, b) =>
                          a.label.localeCompare(b.label)
                        )
                      : field.options
                  }
                  onChange={(value) => onChange({ [field.name]: value })}
                />
              </Col>
            )
        )}
      </Row>
      <Button color='secondary'>Apply</Button>
    </S.AgentFiltersForm>
  )
}

export default AgentFiltersForm
