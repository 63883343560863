import Button from '@global/Button'
import IconCalendar from '@icons/Clendar'
import IconPlus from '@icons/Plus'
import 'react-datepicker/dist/react-datepicker.css'
import { useEffect, useState } from 'react'
import Modal from '@global/Modal'
import { Router } from '@routes'
import { UrlBuilder } from '@lib/helpers'
import format from 'date-fns/format'
import isValid from 'date-fns/isValid'
import ResultsBarMobileBottomMenu from '@components/PropertySearchResults/ResultsBarMobileBottomMenu'
import useWindowSize from '@hooks/useWindowSize'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import * as S from './TimeableForm.styled'

const ReactDatePicker = dynamic(() => import('react-datepicker'), {
  ssr: false,
})

const TimeableForm = (props) => {
  const windowSize = useWindowSize()
  const router = useRouter()
  const [isOpen, setIsOpen] = useState(false)
  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange

  const toggleModal = () => {
    setIsOpen((prev) => !prev)
  }

  const onViewChange = (view) => {
    if (
      (view === 'inspections' &&
        Router.router?.asPath?.includes('open-for-inspections')) ||
      (view === 'auctions' && Router.router?.asPath?.includes('auctions'))
    ) {
      toggleModal()
    } else {
      props.onViewChange(view)
    }
  }

  const updateFilter = (dateFrom, dateTo) => {
    setIsOpen(false)
    const { state } = Router.router
    const { slug, ...rest } = state.query

    const params = {
      ...rest,
      page: 1,
    }

    if (dateFrom) {
      params.date_from = format(dateFrom, 'yyyy-MM-dd')
    } else {
      delete params.date_from
    }

    if (dateTo) {
      params.date_to = format(dateTo, 'yyyy-MM-dd')
    } else {
      delete params.date_to
    }

    Router.pushRoute(
      UrlBuilder(`/${state.query.slug}/`, {
        ...params,
      })
    )
  }

  useEffect(() => {
    const { query } = router

    if (
      query.date_from &&
      isValid(new Date(query.date_from)) &&
      query.date_to &&
      isValid(new Date(query.date_to))
    ) {
      setDateRange([new Date(query.date_from), new Date(query.date_to)])
    } else {
      setDateRange([null, null])
    }
  }, [router.query])

  return (
    <>
      <S.TimeableForm>
        <S.TimeableFormGroup>
          <S.TimeableGroupTitle>Create A Timetable</S.TimeableGroupTitle>
          <S.TimeableGroupDes>
            Select the dates you’d like to view Inspections by
          </S.TimeableGroupDes>
        </S.TimeableFormGroup>
        <S.TimeableFormGroup>
          <div>
            From
            <Button
              startIcon={startDate ? null : <IconPlus />}
              endIcon={<IconCalendar />}
              border
              color='secondary'
              onClick={() => setIsOpen(true)}
            >
              {startDate && isValid(startDate)
                ? format(startDate, 'E dd MMM yyyy')
                : 'Select a date'}
            </Button>
          </div>
          <div>
            To
            <Button
              startIcon={endDate ? null : <IconPlus />}
              endIcon={<IconCalendar />}
              border
              color='secondary'
              onClick={() => setIsOpen(true)}
            >
              {endDate !== null && isValid(endDate)
                ? format(endDate, 'E dd MMM yyyy')
                : 'Select a date'}
            </Button>
          </div>
          <S.ClearButton onClick={() => updateFilter()}>Clear</S.ClearButton>
        </S.TimeableFormGroup>
      </S.TimeableForm>
      <Modal
        className='timeable-modal'
        open={isOpen}
        toggleModal={toggleModal}
        title='Select dates'
      >
        <S.TimeableSelectWrap>
          <ReactDatePicker
            selected={startDate}
            minDate={new Date()}
            startDate={startDate}
            endDate={endDate}
            onChange={(update) => {
              setDateRange(update)
            }}
            selectsRange
            monthsShown={windowSize?.width > 767 ? 2 : 1}
            shouldCloseOnSelect={false}
            inline
          />
          <S.TimeableFormActions>
            <S.ClearButton onClick={() => setDateRange([null, null])}>
              Clear
            </S.ClearButton>
            <Button
              color='primary'
              onClick={() => updateFilter(startDate, endDate)}
            >
              Apply
            </Button>
          </S.TimeableFormActions>
        </S.TimeableSelectWrap>
      </Modal>

      <ResultsBarMobileBottomMenu onViewChange={onViewChange} />
    </>
  )
}

export default TimeableForm
