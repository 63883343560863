import styled from 'styled-components'
import { COLORS } from '@styles/colors'
import { DEVICES } from '@constants/global'
import { FONTS, text4XL } from '@styles/fonts'

export const AgentsSearchForm = styled.div`
  padding-top: 134px;

  @media ${DEVICES.mobile} {
    padding-top: 44px;
  }
`

export const SearchFormHeading = styled.h1`
  ${text4XL};
  color: ${COLORS.secondary};
  margin-bottom: 32px;
`

export const FormGroup = styled.div`
  position: relative;
  z-index: 5;
  padding: 16px 24px;
  background-color: ${COLORS.nature[95]};
  display: flex;
  align-items: center;
  max-width: 830px;
`

export const Form = styled.form`
  margin-bottom: 86px;

  @media ${DEVICES.mobile} {
    margin: 0px -15px 28px -15px;
    background-color: ${COLORS.nature[95]};

    ${FormGroup} {
      padding: 16px;

      > button {
        display: none;
      }
    }
  }
`

export const SelectedFilters = styled.div`
  display: flex;
  margin: 0 28px;
  align-items: center;
  color: ${COLORS.secondary};
  font-family: ${FONTS.medium};
  cursor: pointer;

  svg {
    margin-left: 8px;
    width: 18px;
    height: 18px;
  }
  @media ${DEVICES.mobile} {
    margin-left: 20px;
    margin-right: 0px;
  }
`

export const CloseButton = styled.div`
  display: none;
  padding: 16px 15px 12px;
  color: ${COLORS.secondary};
  cursor: pointer;

  > svg {
    margin-left: 10px;
  }

  @media ${DEVICES.mobile} {
    display: flex;
    justify-content: end;
    align-items: center;
  }
`
