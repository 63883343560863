import { COLORS } from '@styles/colors'
import { FONTS } from '@styles/fonts'
import styled from 'styled-components'

export const SidebarGroup = styled.div`
  margin-bottom: 36px;
`

export const SidebarGroupTitle = styled.h4`
  margin-bottom: 12px;
`

export const AwardInfo = styled.div`
  border-bottom: 1px solid ${COLORS.grey[20]};
  padding-bottom: 20px;
  margin-bottom: 40px;
`

export const AwardInfoDate = styled.div`
  margin-bottom: 12px;
  color: ${COLORS.navi};

  span {
    font-family: ${FONTS.bold};
    letter-spacing: 1.4px;
    color: ${COLORS.secondary};
    text-transform: uppercase;
    margin-right: 16px;
  }
`
