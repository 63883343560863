import Image from '@global/Image'
import { Container } from '@styles/global.styled'
import * as S from './SuburbProfileHero.styled'

const SuburbProfileHero = ({ title, subTitle, image }) => {
  return (
    <Container>
      <S.SuburbProfileHero>
        <S.SuburbProfileHeroInfo>
          <S.Title>{title}</S.Title>
          <S.SubTitle>{subTitle}</S.SubTitle>
        </S.SuburbProfileHeroInfo>
        <S.SuburbProfileHeroImage>
          <Image src={image} alt={title} />
        </S.SuburbProfileHeroImage>
      </S.SuburbProfileHero>
    </Container>
  )
}

export default SuburbProfileHero
