import { useContext, useEffect, useRef, useState } from 'react'
import { reportIssue } from '../lib/api-services/ErrorService'
import { detect } from 'detect-browser'
import { UserContext } from '../context/UserContext'
import { titleCase } from '../lib/helpers'
import Errors from '@components/Errors'
const browser = detect()

const Error = ({ pageData: { statusCode, statusText } }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const userContext = useContext(UserContext)
  const [reportTxt, setReportTxt] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const [msg, setMsg] =
    useState(`Thank you, an error report has been sent to our website
  developers.`)

  const wrapperRef = useRef(null)

  // useEffect(() => {
  //   if (isOpen) {
  //     // Bind the event listener
  //     document.addEventListener('mousedown', handleClickOutside)
  //     return () => {
  //       // Unbind the event listener on clean up
  //       document.removeEventListener('mousedown', handleClickOutside)
  //     }
  //   }
  // }, [wrapperRef, isOpen])

  // const handleClickOutside = (event) => {
  //   if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
  //     setIsOpen(false)
  //   }
  // }

  const handleClickReportIssues = async () => {
    const requesBody = {
      error_msg: `${statusCode} ${
        statusText ? titleCase(statusText) : 'Undefined error'
      }`,
      browser_type: `${browser.name} ${browser.version} ${browser.os}`,
      page: window.location.pathname,
      message: reportTxt,
    }
    requesBody['user_information'] =
      userContext.userProfile && userContext.userProfile.pk
        ? `${userContext.userProfile.first_name} ${userContext.userProfile.last_name} ${userContext.userProfile.email} ${userContext.userProfile.pk}`
        : 'User not logged in'

    try {
      setIsSuccess(true)
      setIsLoading(true)
      await reportIssue(requesBody)
      setIsLoading(false)
    } catch (error) {
      setMsg('Something went wrong!')
      setIsLoading(false)
    }
  }

  return (
  <Errors status={statusCode}/>
  )
}
Error.defaultProps = {
  title: 'Internal Server Error',
  pageData: {
    statusCode: 500,
    statusText: 'Internal Server Error',
  },
  button: {
    label: 'go to homepage',
    link: '/',
  },
}

export default Error
