import { DEVICES } from '@constants/global'
import { COLORS } from '@styles/colors'
import { text2XL, text3XL } from '@styles/fonts'
import styled from 'styled-components'

export const RegionCardTitle = styled.h3`
  ${text3XL};
  color: ${COLORS.white};
  letter-spacing: 2px;
  position: absolute;
  left: 30px;
  bottom: 30px;

  a {
    color: ${COLORS.white};
  }
`

export const RegionCard = styled.div`
  position: relative;
  margin-bottom: 64px;
  border-radius: 0px 0px 100px 0px;
  overflow: hidden;
  line-height: 0;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0) 60.44%
    ),
    url(<path-to-image>), lightgray 50% / cover no-repeat;

  img {
    width: 100%;
    font-size: 0;
    line-height: 0;
  }

  @media ${DEVICES.mobile} {
    margin-bottom: 20px;
    border-radius: 0px 0px 60px 0px;

    ${RegionCardTitle} {
      ${text2XL};
      left: 20px;
      bottom: 20px;
    }
  }
`
