import { UrlBuilder } from '../helpers'
import { apiCall } from '../apiServerHelper'
import { apis } from '../apiUrls'

export const getRedirects = async path => {
  let url = apis.REDIRECTS
  if (path) {
    url = UrlBuilder(url, { old_path: path })
  }
  return await apiCall(url)
}
