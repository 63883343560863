import IconArrowRightCircleFilled from '@icons/ArrowRightCircleFilled'
import { useState } from 'react'
import { Col, Row } from '@styles/global.styled'
import { formatDate } from '@lib/date'
import * as G from '../ListingCardHorizontal.styled'
import * as S from './ListingCardInspection.styled'

const ListingCardInspections = ({ inspections, timezone, inspection }) => {
  const [nextInspection, setNextInspection] = useState(1) // index of inspection
  let otherDatesLabel = 'Other Dates'
  const otherInspections = inspections?.length - 2
  if (otherInspections > 0) {
    const additionalText =
      otherInspections > 2 ? otherInspections : `+ ${otherInspections} More`
    otherDatesLabel += ` (${additionalText})`
  }

  return (
    <G.ListingCardFooter>
      <Row gutter={6}>
        <Col xs={5} sm={5}>
          <ListingCardInspection
            label='Next Open'
            inspection={inspection}
            timezone={timezone}
            primary
          />
        </Col>
        <Col xs={7} sm={7}>
          {otherInspections >= 0 && (
            <ListingCardInspection
              label={otherDatesLabel}
              mobileLabel='More Dates'
              inspection={inspections?.[nextInspection]}
              timezone={timezone}
            />
          )}
        </Col>
        {otherInspections > 0 && nextInspection <= otherInspections && (
          <IconArrowRightCircleFilled
            onClick={() => setNextInspection(nextInspection + 1)}
          />
        )}
      </Row>
    </G.ListingCardFooter>
  )
}

const ListingCardInspection = ({
  label,
  mobileLabel,
  inspection,
  timezone,
  primary,
}) => {
  return (
    <S.ListingCardInspection primary={primary}>
      <label>
        <span>{label}</span>
        <S.MobileLabel>{mobileLabel || label}</S.MobileLabel>
      </label>
      {formatDate(inspection?.inspection_time, 'E MMMM do', timezone)}
      <br />
      {formatDate(
        inspection?.inspection_time,
        'h:mm aaa',
        timezone
      ).toUpperCase()}
    </S.ListingCardInspection>
  )
}

export default ListingCardInspections
