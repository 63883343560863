import ProjectCard from '@global/ProjectCard'
import LoadmoreInfo from '@global/SearchResults/LoadmoreInfo'
import NoResults from '@global/SearchResults/NoResults'
import SearchResultsCount from '@global/SearchResults/SearchResultsCount'
import SearchResultsTabs from '@global/SearchResults/SearchResultsTabs'
import { UrlBuilder } from '@lib/helpers'
import { itemShowPerPage } from '@lib/variables'
import { Router } from '@routes'
import dynamic from 'next/dynamic'
import React from 'react'

const ResultsMap = dynamic(() => import('@global/SearchResults/ResultsMap'), {
  ssr: false,
})

const ProjectResults = ({
  projects,
  getProjects,
  setProjects,
  page,
  setPage,
  metaData,
  queryParams,
}) => {
  const onViewChange = (view) => {
    Router.pushRoute(
      UrlBuilder(`/${Router.router.state.query.slug}/`, { view })
    )
  }

  return (
    <SearchResultsTabs activeTab={queryParams.view} onViewChange={onViewChange}>
      <SearchResultsCount
        total={metaData.total}
        numberShowing={
          queryParams.view === 'map'
            ? false
            : page * itemShowPerPage.ProjectsSearchListPage
        } // Hide on map view
      />
      {projects.count > 0 ? (
        queryParams.view === 'map' ? (
          <ResultsMap
            items={projects.results}
            renderPopup={(project) => (
              <ProjectCard
                {...project}
                status={project.status?.toLowerCase()}
                mapResults
              />
            )}
          />
        ) : (
          <React.Fragment>
            {projects.results.map((item) => (
              <ProjectCard
                {...item}
                status={item.status?.toLowerCase()}
                key={item.id}
              />
            ))}
            <LoadmoreInfo
              total={metaData.total}
              numberShowing={page * itemShowPerPage.ProjectsSearchListPage}
              queryParams={queryParams}
              apiCall={getProjects}
              page={page}
              onSuccess={(data, newPage) => {
                const newResults = projects.results.concat(data)
                setProjects({ ...projects, results: newResults })
                setPage(newPage)
              }}
            />
          </React.Fragment>
        )
      ) : (
        <NoResults
          title='Sorry, we couldn’t find any projects matching your search.'
          description='Try searching with another term, or extending your suburb reach.'
        />
      )}
    </SearchResultsTabs>
  )
}

export default ProjectResults
