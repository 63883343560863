import { DEVICES } from '@constants/global'
import { COLORS } from '@styles/colors'
import styled from 'styled-components'

export const BackToTop = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${COLORS.secondary};
  position: fixed;
  bottom: 42px;
  right: 42px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLORS.white};

  svg {
    transform: rotate(180deg);
  }

  &:hover {
    background-color: ${COLORS.primary};
  }

  @media ${DEVICES.tabletMini} {
    bottom: 106px;
    right: 16px;
  }
`
