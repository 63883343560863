import React from 'react'
import { Col, Container, Row } from '@styles/global.styled'
import * as S from './Thankyou.styled'

const Thankyou = () => {
  return (
    <Container>
      <Row>
        <Col>
          <S.ThankyouTitle>
            We appreciate your interest in our real estate appraisal services
          </S.ThankyouTitle>

          <S.ThankyouSubTitle>
            Thank you for choosing Barry Plant for your appraisal needs. We have
            received your appraisal request, and we&apos;re excited to assist
            you.
          </S.ThankyouSubTitle>

          <S.ThankyouContent>
            <p>
              Our dedicated team of experts will carefully review the
              information you provided and get back to you promptly with further
              details.
            </p>
            <p>
              At Barry Plant, we understand the importance of accurate property
              value estimates. Our experienced appraisers utilise their
              expertise and extensive market knowledge to provide you with a
              comprehensive analysis. Whether you&apos;re considering selling,
              leasing or refinancing, or simply curious about your
              property&apos;s value, we&apos;re here to help.
            </p>
            <p>
              Rest assured, your request is a top priority for us. We will
              thoroughly evaluate the unique features of your property, local
              market trends, and other essential factors to ensure we deliver an
              accurate and reliable appraisal report. Our commitment to
              excellence has earned us a reputation as a trusted and dependable
              real estate agency.
            </p>
            <p>
              Once again, thank you for choosing Barry Plant. We appreciate your
              trust in our expertise and look forward to providing you with a
              professional appraisal that exceeds your expectations.
            </p>
          </S.ThankyouContent>
        </Col>
      </Row>
    </Container>
  )
}

export default Thankyou
