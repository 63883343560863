import { DEVICES } from '@constants/global'
import { COLORS } from '@styles/colors'
import { FONTS, text3XL, text4XL, textLG } from '@styles/fonts'
import styled, { css } from 'styled-components'

export const SuburbProfilesTitle = styled.h1`
  ${text4XL};
`

export const SuburbProfilesSubTitle = styled.h2`
  ${textLG};
  font-family: ${FONTS.medium};
  text-transform: unset;
  letter-spacing: 0.1px;
  margin-top: 32px;
`

export const SuburbProfilesContent = styled.div`
  color: ${COLORS.secondary};
  margin-top: 24px;
`

export const SuburbProfilesHero = styled.div`
  margin-bottom: -36px;

  padding-top: 54px;
  color: ${COLORS.secondary};
  max-width: 530px;

  ${({ isRegion }) =>
    isRegion &&
    css`
      padding-top: 135px;
    `}

  @media ${DEVICES.mobile} {
    padding-top: 42px;
    margin-bottom: 0;

    ${SuburbProfilesTitle} {
      ${text3XL};
    }

    ${SuburbProfilesSubTitle} {
      margin-top: 28px;
    }
  }
`
