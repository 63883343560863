import SuburbProfilesHero from '@components/SuburbProfiles/SuburbProfilesHero'
import WagtailBlocksSection from '../components/global/Blocks/WagtailBlocksSection'
import { getChildPages } from '../lib/api-services/cmsService'
import { apiFieldsQuery, itemShowPerPage } from '../lib/variables'
import TwinEqualCTA from '@global/Blocks/TwinEqualCTA'
import { Container, Section } from '@styles/global.styled'
import Breadcrumbs from '@global/Breadcrumbs'

const RegionIndexPage = ({
  region,
  pageData: { title, preamble, content_blocks },
}) => {
  return (
    <>
      <Container>
        <Breadcrumbs
          breadcrumbs={[
            { label: 'Suburb Profiles', url: '/suburb-profile' },
            { label: title, url: '#' },
          ]}
        />
      </Container>
      <SuburbProfilesHero title={title} content={preamble} />
      <Section withPaddingBottom={!(content_blocks?.length > 0)}>
        <TwinEqualCTA
          title={region.title}
          titleStyle='normal'
          items={region.items}
        />
      </Section>
      <WagtailBlocksSection blocks={content_blocks} />
    </>
  )
}

RegionIndexPage.getInitialProps = async (ctx, pageData) => {
  let childrenPages = await getChildPages(
    'suburbs.RegionPage',
    pageData.id,
    itemShowPerPage.RegionIndexPage,
    null,
    null,
    apiFieldsQuery.RegionPage
  )

  return {
    region: {
      title: 'Explore Suburbs by Municipality',
      items:
        childrenPages.items?.map((item) => {
          return {
            description: item.hover_text,
            title: item.title,
            image: item?.hero_image_small?.url,
            url: item.meta.path,
          }
        }) ?? [],
    },
  }
}
export default RegionIndexPage
