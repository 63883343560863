import styled from 'styled-components'
import { COLORS } from '@styles/colors'
import { DEVICES } from '@constants/global'
import { FONTS } from '@styles/fonts'

export const ProjectSearchForm = styled.div`
  padding-top: 80px;
  margin-bottom: 32px;

  @media ${DEVICES.mobile} {
    padding: 0;
    margin: 0 -16px 28px;
  }

  .bp-autocomplete {
    width: 100%;

    &__indicator {
      padding: 0;
      cursor: pointer;
    }

    &__control {
      padding: 8px 20px 8px 46px;
      border: 1px solid #dbd7cd;
      border-radius: 4px;
      width: 100%;
      font-size: 16px;
      color: ${COLORS.secondary};
      box-shadow: none;

      &::placeholder {
        color: ${COLORS.secondary};
      }
    }

    &__input-container {
      font-size: 16px;
      color: ${COLORS.secondary};
    }

    &__multi-value {
      background: none;
      position: relative;
      margin: 0;

      &::after {
        position: absolute;
        bottom: 4px;
        right: -2px;
        content: ',';
      }

      &:nth-last-child(2) {
        &::after {
          display: none;
        }
      }

      &__label {
        color: ${COLORS.secondary};
        font-family: ${FONTS.regular};
        font-size: 16px;
        text-transform: capitalize;
      }

      &__remove {
        display: none;
      }
    }

    &__single-value,
    &__placeholder,
    &__input {
      color: ${COLORS.secondary};
    }
  }
`

export const FormGroup = styled.div`
  flex: 1;
  position: relative;

  > svg {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 4;
    color: ${COLORS.secondary};
  }
`

export const SearchForm = styled.div`
  padding: 16px 24px;
  background-color: ${COLORS.nature[95]};
  display: flex;
  align-items: center;
  max-width: 830px;

  button {
    margin-left: 28px;
    min-width: 132px;
    min-height: 42px;
  }

  @media ${DEVICES.mobile} {
    background-color: ${COLORS.nature[95]};
    padding: 16px;

    ${FormGroup} {
      > button {
        display: none;
      }
    }
  }
`
