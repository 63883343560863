import React from 'react'
import * as S from './TestimonialCard.Styled'
import { defaultImages } from '@lib/variables'
import { Link } from '@routes'
import htmr from 'htmr'
import { stripTags, truncateText } from '@lib/helpers'

const TestimonialCard = ({ testimonial }) => {
  const link = testimonial.slug && `testimonials/${testimonial.slug}/${testimonial.id}`
  return (
    <S.TestimonialCard>
      <S.TestimonialCardImage>
        {link ? (
          <Link route={link} passHref>
            <S.TestimonialImage
              src={testimonial?.image || testimonial?.image_thumbnail}
              alt={testimonial.name}
              fallbackImage={defaultImages.largeNavy}
            />
          </Link>
        ) : (
          <S.TestimonialImage
            src={testimonial?.image || testimonial?.image_thumbnail}
            alt={testimonial.name}
            fallbackImage={defaultImages.largeNavy}
          />
        )}
      </S.TestimonialCardImage>
      <S.TestimonialCardBody>
        {testimonial.offices.length > 0 && (
          <S.TestimonialOffices>
            {`Office${testimonial.offices.length > 1 ? 's' : ''}: 
              ${testimonial.offices
                .map((office, index) => (index > 0 ? `- ${office}` : office))
                .join(' ')}`}
          </S.TestimonialOffices>
        )}
        <S.TestimonialTitle>
          {link ? (
            <Link route={link} passHref>
              {testimonial.name}
            </Link>
          ) : testimonial.name
          }
        </S.TestimonialTitle>
        {testimonial.comments && (
          <S.TestimonialDescription>
            {`“${htmr(
              truncateText(stripTags(testimonial.comments), 280, '…')
            )}”`}
          </S.TestimonialDescription>
        )}
      </S.TestimonialCardBody>
    </S.TestimonialCard>
  )
}

export default TestimonialCard
