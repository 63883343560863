import styled, { css } from 'styled-components'
import { COLORS } from '@styles/colors'
import { DEVICES } from '@constants/global'
import Image from '@global/Image'
import { FONTS, text2XL, text3XL, textBase, textLG, textXS } from '@styles/fonts'


export const InvestorHeroWrap = styled.div`

  .col-left {
    flex-direction: column;
    gap: 20px;
  }
  
  .col-right {
    justify-content: flex-end;
    padding-right: 0;
  }

  .investor-container {
    width: 100%;
    padding-top: 72px;
    padding-right: 0;
  }

  .investor-row {

  }


  @media ${DEVICES.mobile} {
    .investor-container {
      padding-right: 16px;
      padding-top: 0;
    }

    .investor-row {
      flex-direction: column-reverse;
    }

    .col-left {
      gap: 24px;
    }
  }
`

export const Title = styled.h3`
  ${text3XL}
  color: ${COLORS.secondary};
  font-family: ${FONTS.medium};
  font-weight: 420;
  text-transform: uppercase;
  margin-bottom: 12px;

  @media ${DEVICES.mobile} {
    ${text2XL}
    font-style: normal;
    font-weight: 450;
    letter-spacing: 1.6px;
    margin-bottom: 0;
  }
`

export const SubTitle = styled.h4`
  ${text2XL}
  color: ${COLORS.secondary};
  font-family: ${FONTS.medium};
  font-weight: 420;
  letter-spacing: 0.1px;
  text-transform: none;

  @media ${DEVICES.mobile} {
    ${textLG}
  }
`

export const ListItems = styled.ul`
  ${textBase}
  color: ${COLORS.secondary};
  font-family: ${FONTS.regular};
  font-weight: 390;
  letter-spacing: 0.1px;
  padding: 20px 28px;

  @media ${DEVICES.mobile} {
    padding: 0px 28px;
  }
`

export const Item = styled.li`

`

export const Form = styled.div`
  margin-bottom: 7px;
`

export const Terms = styled.div`
  ${textXS}
  color: ${COLORS.secondary};
  font-family: ${FONTS.regular};
  font-weight: 390;
  letter-spacing: 0.2px;
`

export const TermsBold = styled.strong`
  ${textXS}
  color: ${COLORS.secondary};
  font-family: ${FONTS.medium};
  font-weight: 420;
  letter-spacing: 0.2px;
`

export const HeroImage = styled(Image)`
  margin-top: -32px;

  @media ${DEVICES.mobile} {
    margin-top: 0px;
  }
`