// import HomePage from './HomePage'
import ListingSearchPage from './ListingSearchPage'
import AgentIndexPage from './AgentIndexPage'
// import AgentPage from './AgentPage'
import OfficeIndexPage from './OfficeIndexPage'
import SellingPage from './SellingPage'
// import CreateAccountPage from './CreateAccountPage'
// import LoginPage from './LoginPage'
// import OfficePage from './OfficePage'
// import PropertyAlertLanding from './PropertyAlertLanding'
import CareersLandingPage from './CareersLandingPage'
// import GenericPageTemplate from './GenericPageTemplate'
import MediaHubLandingPage from './MediaHubLandingPage'
// import WhyBarryPlantPage from './WhyBarryPlantPage'
import RegionIndexPage from './RegionIndexPage'
import CareerIndexPage from './CareerIndexPage'
import BlogIndexPage from './BlogIndexPage'
import BarryPlantTVIndexPage from './BarryPlantTVIndexPage'
import ResourcesIndexPage from './ResourcesIndexPage'
import RegionPage from './RegionPage'
import SuburbPage from './SuburbPage'
import LocalInformationLanding from './LocalInformationLanding'
import AppraisalRequestPage from './AppraisalRequestPage'
// import CalculatorPage from './CalculatorPage'
import TestimonialIndexPage from './TestimonialIndexPage'
import AwardIndexPage from './AwardIndexPage'
import AwardPage from './AwardPage'
import LifestyleCategoryIndex from './LifestyleCategoryIndex'
import LifestyleCategoryPage from './LifestyleCategoryPage'
import Project from './Project'
// import ProjectLanding from './ProjectLanding'
import BlogPost from '../components/blog/BlogPost'
import CareerPage from './CareerPage'
import MaintenanceRequestPage from './MaintenanceRequestPage'
import ThankYouPage from './ThankYouPage'
import FloatingFormPage from './FloatingFormPage'
import InvestorLandingPage from './InvestorLandingPage'
import KitchenSinkPage from './KitchenSinkPage'
import ProjectLandingIndex from './ProjectLandingIndex'

import dynamic from 'next/dynamic'
const HomePage = dynamic(() => import('./HomePage'))
const AgentPage = dynamic(() => import('./AgentPage'))
const CreateAccountPage = dynamic(() => import('./CreateAccountPage'))
const LoginPage = dynamic(() => import('./LoginPage'))
const OfficePage = dynamic(() => import('./OfficePage'))
const PropertyAlertLanding = dynamic(() => import('./PropertyAlertLanding'))
const ErrorPage = dynamic(() => import('./ErrorPage'))
const ProjectLanding = dynamic(() => import('./ProjectLanding'))
const GenericPageTemplate = dynamic(() => import('./GenericPageTemplate'))
const WhyBarryPlantPage = dynamic(() => import('./WhyBarryPlantPage'))
const CalculatorPage = dynamic(() => import('./CalculatorPage'))
const IframePage = dynamic(() => import('./IframePage'))

export default {
  HomePage,
  ListingSearchPage,
  AgentIndexPage,
  AgentPage,
  OfficeIndexPage,
  SellingPage,
  CreateAccountPage,
  LoginPage,
  OfficePage,
  PropertyAlertLanding,
  CareersLandingPage,
  GenericPageTemplate,
  MediaHubLandingPage,
  WhyBarryPlantPage,
  RegionIndexPage,
  CareerIndexPage,
  BlogIndex: BlogIndexPage,
  BarryPlantTVIndexPage,
  ResourcesIndexPage,
  RegionPage,
  SuburbPage,
  LocalInformationLanding,
  AppraisalRequestPage,
  CalculatorPage,
  IframePage,
  TestimonialIndexPage,
  AwardIndexPage,
  AwardPage,
  LifestyleCategoryIndex,
  LifestyleCategoryPage,
  Project,
  ProjectLanding,
  ErrorPage,
  BlogPost,
  CareerPage,
  MaintenanceRequestPage,
  FloatingFormPage,
  ThankYouPage,
  InvestorLandingPage,
  KitchenSinkPage,
  ProjectLandingIndex,
}
