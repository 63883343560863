import Image from '@global/Image'
import { Link } from '@routes'
import * as S from './RegionCard.styled'

const RegionCard = ({ region }) => {
  return (
    <S.RegionCard>
      <Link route={region.link} passHref>
        <Image src={region.image} alt={region.title} />
      </Link>
      <S.RegionCardTitle>
        <Link route={region.link} passHref>
          {region.title}
        </Link>
      </S.RegionCardTitle>
    </S.RegionCard>
  )
}

RegionCard.defaultProps = {}

export default RegionCard
