import { DEVICES } from '@constants/global'
import styled from 'styled-components'

export const SuburbContent = styled.div`
  width: 60%;

  @media ${DEVICES.tablet} {
    width: 100%;
  }
`
