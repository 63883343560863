const IconOfficeLocation = (props) => {
  return (
    <svg
      width='21'
      height='20'
      viewBox='0 0 21 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M9.49992 15L7.83325 14.1666M7.83325 14.1666L2.83325 16.6666V5.83331L7.83325 3.33331M7.83325 14.1666V3.33331M7.83325 3.33331L12.8333 5.83331M12.8333 5.83331L17.8333 3.33331V9.58331M12.8333 5.83331V9.99998M17.1659 16.8327L18.6659 18.3327M12.8333 15C12.8333 15.663 13.0966 16.2989 13.5655 16.7677C14.0343 17.2366 14.6702 17.5 15.3333 17.5C15.9963 17.5 16.6322 17.2366 17.101 16.7677C17.5699 16.2989 17.8333 15.663 17.8333 15C17.8333 14.3369 17.5699 13.7011 17.101 13.2322C16.6322 12.7634 15.9963 12.5 15.3333 12.5C14.6702 12.5 14.0343 12.7634 13.5655 13.2322C13.0966 13.7011 12.8333 14.3369 12.8333 15Z'
        stroke='currentColor'
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default IconOfficeLocation
