import { useState } from 'react'
import IconPlusBig from '@icons/PlusBig'
import IconMinus from '@icons/Minus'
import Button from '@global/Button'
import AgentFiltersForm from '../AgentFiltersForm'
import SearchInput from '@global/FormInput/SearchInput'
import IconClose from '@icons/Close'
import { useDebouncedCallback } from 'use-debounce'
import * as S from './AgentSearchForm.styled'

const AgentSearch = ({ dynamicFields, onSubmit, title, initialValues }) => {
  const [isOpen, setOpen] = useState(false)

  const toggleFilter = () => {
    setOpen(!isOpen)
  }

  const debounced = useDebouncedCallback((search) => {
    onSubmit({ search, page: 1 })
  }, 1000)

  return (
    <S.AgentsSearchForm>
      <S.SearchFormHeading>{title}</S.SearchFormHeading>
      <S.Form noValidate onSubmit={(e) => e.preventDefault()}>
        {isOpen && (
          <S.CloseButton onClick={() => setOpen(false)}>
            Close <IconClose />
          </S.CloseButton>
        )}
        <S.FormGroup>
          <SearchInput
            key={initialValues.search}
            defaultValue={initialValues.search}
            placeholder='Find by name'
            onChange={(e) => debounced(e.target.value)}
          />
          <S.SelectedFilters onClick={toggleFilter}>
            Filter
            {isOpen ? <IconMinus /> : <IconPlusBig />}
          </S.SelectedFilters>
          <Button color='secondary' size='big'>
            Search
          </Button>
        </S.FormGroup>
        <AgentFiltersForm
          open={isOpen}
          values={initialValues}
          dynamicFields={dynamicFields}
          onChange={onSubmit}
        />
      </S.Form>
    </S.AgentsSearchForm>
  )
}

export default AgentSearch
