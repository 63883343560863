import { COLORS } from "@styles/colors";
import { FONTS, textBase, textSM } from '@styles/fonts'
import styled from "styled-components";
import { DEVICES } from '@constants/global'

export const BlogSidebarWrap = styled.div`
    position: relative;
`

export const BlogSidebarInfoWrap = styled.div`
  
`

export const BlogSidebarInfo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 12px;
`

export const BlogSidebarCategory = styled.div`
  ${textBase};
  color: ${COLORS.secondary};
  font-family: ${FONTS.bold};
  text-transform: uppercase;
  flex: 3;
`

export const BlogSidebarDate = styled.div`
  ${textBase};
  color: ${COLORS.secondary};
  font-family: ${FONTS.regular};
  flex: 2;
`

export const BlogSidebarShare = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  border-bottom: 1px solid ${COLORS.grey[20]};

  .favourite-heart {
    display: flex;
    flex-direction: row-reverse;
  }
`

export const BlogSidebarFavourite = styled.div`
  > div {
    display: flex;
    flex-direction: row-reverse;
    
    > span {
      color: ${COLORS.secondary};
    }
  }
`

export const BlogSidebarAuthor = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 40px;
`

export const BlogSidebarAuthorTitle = styled.div`
  ${textSM};
  color: ${COLORS.secondary};
  font-family: ${FONTS.bold};
  text-transform: uppercase;
`

export const BlogSidebarSignUpBanner = styled.div`
    @media ${DEVICES.mobile}{
        display: none;
    }
`

export const AuthorWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`
