import styled from 'styled-components'
import { COLORS } from '@styles/colors'
import { FONTS, text3XL, text4XL, textLG, textSM } from '@styles/fonts'
import { DEVICES } from '@constants/global'

export const SuburbProfileHeroInfo = styled.div`
  flex: 0 0 42%;
  width: 42%;
  display: flex;
  flex-direction: column;
  padding-right: 32px;
`

export const SubTitle = styled.h1`
  ${text4XL};
  margin-bottom: 20px;
  color: ${COLORS.secondary};
`

export const Title = styled.h3`
  ${textLG};
  text-transform: capitalize;
  color: ${COLORS.navi};
  font-family: ${FONTS.medium};
  margin-bottom: 12px;
`

export const SuburbProfileHeroImage = styled.div`
  flex: 1;

  img {
    border-radius: 0 0 100px 0;
    width: 100%;
  }
`

export const SuburbProfileHero = styled.div`
  display: flex;
  padding-top: 30px;

  @media ${DEVICES.mobile} {
    flex-direction: column;

    ${SuburbProfileHeroInfo} {
      flex: 1;
      width: 100%;
      padding-bottom: 18px;
    }

    ${SubTitle} {
      ${text3XL};
      margin-bottom: 12px;
    }

    ${Title} {
      ${textSM};
      margin-bottom: 10px;
    }

    ${SuburbProfileHeroImage} {
      margin-bottom: 28px;
    }
  }
`
