const IconSearch = () => (
  <svg
    version='1.1'
    id='Layer_1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 28 28'
  >
    <path
      fill='currentColor'
      className='st0'
      d='M28.1,26.2l-6.4-6.4c1.9-2.1,3-4.9,3-7.9C24.8,5.3,19.5,0,12.9,0C6.3,0,1,5.3,1,11.9c0,6.6,5.3,11.9,11.9,11.9
c2.6,0,5.1-0.9,7-2.3l6.4,6.4L28.1,26.2z M3.5,11.9c0-5.2,4.2-9.4,9.4-9.4c5.2,0,9.4,4.2,9.4,9.4c0,5.2-4.2,9.4-9.4,9.4
C7.7,21.3,3.5,17.1,3.5,11.9z'
    />
  </svg>
)

export default IconSearch
