import styled, { css } from 'styled-components'
import { FONTS, text3XL } from '@styles/fonts'

export const NoResultsWrap = styled.div`
  max-width: 500px;
  margin-bottom: 190px;
`

export const NoResultsText = styled.h3`
  ${text3XL};
  font-family: ${FONTS.medium};
  margin-bottom: 20px;
  text-transform: none;
`
