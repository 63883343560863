import { Col, Row } from '@styles/global.styled'
import { formatDate } from '@lib/date'
import * as G from '../ListingCardHorizontal.styled'
import * as S from './ListingCardAuction.styled'

const ListingCardAuctions = ({ auction, timezone, inspection }) => {
  return (
    <G.ListingCardFooter>
      <Row gutter={6}>
        {inspection && (
          <Col xs={5} sm={5}>
            <ListingCardAuction
              label='Next Open'
              time={inspection}
              timezone={timezone}
              primary
            />
          </Col>
        )}
        <Col xs={7} sm={7}>
          <ListingCardAuction
            label='Auction'
            time={auction}
            timezone={timezone}
          />
        </Col>
      </Row>
    </G.ListingCardFooter>
  )
}

const ListingCardAuction = ({ label, time, timezone, primary }) => {
  return (
    <S.ListingCardAuction primary={primary}>
      <label>{label}</label>
      {formatDate(time, 'E MMMM do', timezone)}
      <br />
      {formatDate(time, 'h:mm aaa', timezone).toUpperCase()}
    </S.ListingCardAuction>
  )
}

export default ListingCardAuctions
