import styled from 'styled-components'
import { COLORS } from '@styles/colors'
import { DEVICES } from '@constants/global'
import { FONTS, textBase, textLG } from '@styles/fonts'

export const TimeableForm = styled.div`
  box-shadow: 3px 3px 8px 0px rgba(112, 112, 112, 0.13);
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: ${COLORS.white};

  @media ${DEVICES.mobile} {
    display: none;
  }
`

export const TimeableGroupTitle = styled.span`
  ${textLG};
  display: block;
  text-transform: uppercase;
  font-family: ${FONTS.medium};
  margin-bottom: 2px;
`

export const ClearButton = styled.span`
  cursor: pointer;
  text-decoration: underline;
  color: ${COLORS.secondary};
`

export const TimeableGroupDes = styled.span``

export const TimeableFormGroup = styled.div`
  display: flex;
  color: ${COLORS.secondary};
  padding: 24px 42px;

  &:first-child {
    flex: 1;
    flex-direction: column;
    border-right: 1px solid ${COLORS.grey[10]};
  }

  &:last-child {
    justify-content: flex-end;
    align-items: center;
    font-family: ${FONTS.medium};

    button {
      margin-left: 12px;
    }

    > div {
      margin-right: 20px;
    }

    > span {
      text-decoration: underline;
    }
  }

  @media ${DEVICES.tabletMini} {
    &:first-child {
      display: none;
    }
  }
`

export const TimeableFormActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  border-top: 1px solid ${COLORS.grey[20]};
`

export const TimeableSelectWrap = styled.div`
  > div {
    line-height: 0;
  }

  // Datepicker
  .react-datepicker {
    border: none;
    border-radius: 0;
    margin-top: 40px;
    padding: 0 12px 34px;
    font-family: ${FONTS.medium};
  }
  .react-datepicker__day--keyboard-selected {
    background-color: transparent;
  }
  .react-datepicker__header {
    background: ${COLORS.white};
    border-bottom: none;
    padding: 0;
  }
  .react-datepicker__current-month {
    ${textLG};
    padding-bottom: 24px;
    font-family: ${FONTS.bold};
    letter-spacing: 1.2px;
    text-transform: uppercase;
    color: ${COLORS.secondary};
  }

  .react-datepicker__day-names {
    margin-bottom: 16px;
  }

  .react-datepicker__month-container {
    margin: 0 20px;
  }

  .react-datepicker__month {
    margin: 0;
  }

  .react-datepicker__week {
    .react-datepicker__day:first-child {
      border-radius: 50% 0 0 50%;
    }
    .react-datepicker__day:last-child {
      border-radius: 0 50% 50% 0;
    }
  }

  .react-datepicker__navigation--previous {
    background: url('/imgs/icons/chevron-left.svg') center center no-repeat;
    background-size: 20px 20px;
    width: 20px;
    height: 20px;
    left: 26px;
    top: 3px;

    span {
      display: none;
    }
  }

  .react-datepicker__navigation--next {
    background: url('/imgs/icons/chevron-right.svg') center center no-repeat;
    background-size: 20px 20px;
    width: 20px;
    height: 20px;
    right: 26px;
    top: 3px;

    span {
      display: none;
    }
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    ${textBase};
    font-family: ${FONTS.medium};
    color: ${COLORS.secondary};
    margin: 2px 0;
    border-radius: 0;
    width: 44px;
    line-height: 44px;

    &.react-datepicker__day--disabled {
      color: ${COLORS.grey[60]};
    }

    &:hover {
      border-radius: 50%;
      background-color: #e5f1ff;
    }

    &.react-datepicker__day--weekend {
      &:hover {
        border-radius: 50%;
      }
    }

    &.react-datepicker__day--today {
      background-color: transparent;
      font-family: ${FONTS.medium};
      font-weight: normal;

      &:hover {
        background-color: #e5f1ff;
      }
    }

    &.react-datepicker__day--selected {
      background-color: #e5f1ff;
      border-radius: 50%;
      position: relative;
    }

    &.react-datepicker__day--in-range,
    &.react-datepicker__day--in-selecting-range {
      background-color: #e5f1ff;

      &:hover {
        border-radius: unset;
      }
    }

    &.react-datepicker__day--range-start.react-datepicker__day--range-end {
      border-radius: 50% !important;
    }

    &.react-datepicker__day--range-start,
    &.react-datepicker__day--selecting-range-start {
      border-radius: 50% 0 0 50%;

      &:hover {
        border-radius: 50% 0 0 50%;
      }

      &.react-datepicker__day--selecting-range-end {
        border-radius: 50% !important;
      }
    }

    &.react-datepicker__day--range-end,
    &.react-datepicker__day--selecting-range-end {
      border-radius: 0 50% 50% 0;

      &:hover {
        border-radius: 0 50% 50% 0;
      }
    }

    &.react-datepicker__day--range-start,
    &.react-datepicker__day--selecting-range-start {
      border-radius: 50% 0 0 50%;

      &:hover {
        border-radius: 50% 0 0 50%;
      }
    }

    &.react-datepicker__day--in-range.react-datepicker__day--weekend {
      &.react-datepicker__day--range-start {
        border-radius: 50% 0 0 50%;

        &:hover {
          border-radius: 50% 0 0 50%;
        }
      }

      &:hover {
        border-radius: 50% 0 0 50%;
      }

      &:last-child:hover {
        border-radius: 0 50% 50% 0;
      }
    }
  }
`
