import WagtailBlocksSection from '../components/global/Blocks/WagtailBlocksSection'
import InvestorHero from '@components/Investor/InvestorHero'

const InvestorLandingPage = ({ pageData }) => {
  const {
    title,
    sub_heading,
    bullet_points,
    image,
    form,
    disclaimer_text,
    content_blocks
  } = pageData
  
  return (
    <>
      <InvestorHero 
        formId='investor-hero-form' 
        form={form} 
        title={title}
        sub_heading={sub_heading}
        bullet_points={bullet_points}
        image={image} 
        disclaimer_text = {disclaimer_text}
      />
      <WagtailBlocksSection blocks={content_blocks} />
    </>
  )
}

export default InvestorLandingPage
