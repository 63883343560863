import WagtailBlocksSection from '@global/Blocks/WagtailBlocksSection'
import * as S from './BlogDetail.styled'

const BlogDetail = ({title, intro, article, sideBar}) => {
  const updatedArticle = article.map(item => {
    if (item.type === "blog_image_and_text" || item.type === "image_gallery") {
      return { ...item, title }
    }
    return item
  })
  return (
    <S.BlogDetailWrap>
      <S.BlogTitle>{title}</S.BlogTitle>
      {sideBar && <S.BlogSidebar>{sideBar}</S.BlogSidebar>}
      <S.BlogPreamble>{intro}</S.BlogPreamble>
      <WagtailBlocksSection blocks={updatedArticle} />
    </S.BlogDetailWrap>
  )
}
export default BlogDetail

