import SuburbSEOLinks from '../components/global/SuburbSEOLinks'
import WagtailBlocksSection from '../components/global/Blocks/WagtailBlocksSection'
import { getChildPages } from '@lib/api-services/cmsService'
import Breadcrumbs from '@global/Breadcrumbs'
import { Container } from '@styles/global.styled'
import SuburbProfileHero from '@components/SuburbProfile/SuburbProfileHero'
import SuburbInfo from '@components/SuburbProfile/SuburbInfo'
import SuburbContent from '@components/SuburbProfile/SuburbContent'

const SuburbPage = ({
  sidebar,
  isSuburbPage,
  pageData: {
    title,
    content_blocks,
    state,
    postcode,
    preamble,
    featured_image,
    meta,
    article,
  },
}) => {
  return (
    <>
      <Container>
        <Breadcrumbs
          breadcrumbs={meta.breadcrumbs?.map((it) => ({
            ...it,
            label: it.title,
          }))}
        />
      </Container>
      <SuburbProfileHero
        title={meta.parent.title}
        subTitle={
          <>
            {title}
            <br />
            {state} {postcode}
          </>
        }
        image={featured_image?.url}
      />
      <SuburbInfo suburb={title} preamble={preamble} />

      {article.length > 0 && article[0].value && (
        <SuburbContent content={article[0].value} />
      )}

      <WagtailBlocksSection blocks={content_blocks} />
      <SuburbSEOLinks suburb={title} state={state} slug={meta.slug} />
    </>
  )
}

SuburbPage.getInitialProps = async (ctx, pageData) => {
  let isSuburbPage = false
  if (ctx && ctx.query && ctx.query.slug) {
    isSuburbPage = ctx.query.slug.includes('suburb-profile')
  }
  const { id, lifestyle_categories, property_stats } = pageData
  let sidebar = []
  let childrenPages = await getChildPages('suburbs.SuburbPage', id, 1)

  if (lifestyle_categories) {
    sidebar.push({
      title: 'Lifestyle',
      listItems: lifestyle_categories.map((item) => item.category.title),
    })
  }

  if (property_stats) {
    sidebar = sidebar.concat([
      {
        title: 'Properties for Sale with Barry Plant',
        text: property_stats.for_sale,
        link: `/for-sale/properties-sale/?address_suburb=${pageData.title}`,
        type: 'sale',
      },
    ])

    if (property_stats.sold > 0) {
      sidebar = sidebar.concat([
        {
          title: 'Properties Sold by Barry Plant (since 2016)',
          text: property_stats.sold,
          link: `/sold-properties/?address_suburb=${pageData.title}`,
          type: 'sold',
        },
      ])
    }

    sidebar = sidebar.concat([
      {
        title: 'Properties for Rent with Barry Plant',
        text: property_stats.for_rent,
        link: `/rental-properties/properties-rent/?address_suburb=${pageData.title}`,
        type: 'rent',
      },
    ])

    if (property_stats.leased > 0) {
      sidebar = sidebar.concat([
        {
          title: 'Properties Leased by Barry Plant (since 2016)',
          text: property_stats.leased,
          link: `/rental-properties/properties-rent/?address_suburb=${pageData.title}&status=leased`,
          type: 'leased',
        },
      ])
    }
  }

  return {
    region: {
      title: 'Suburb profile by region',
      blocks: childrenPages.items.map((item) => {
        return {
          copy: item.description,
          title: item.title,
          image: item.featured_image_thumb && item.featured_image_thumb.url,
          link: item.meta.path,
        }
      }),
    },
    sidebar: sidebar,
    isSuburbPage: isSuburbPage,
    pageData,
  }
}
export default SuburbPage
