import { DEVICES } from '@constants/global'
import { COLORS } from '@styles/colors'
import { FONTS, textSM } from '@styles/fonts'
import styled, { css } from 'styled-components'

export const ListingCardImage = styled.div`
  width: 314px;
  position: relative;
  overflow: hidden;
  font-size: 0;
  line-height: 0;
  margin-right: 28px;

  img {
    transition: transform 0.25s linear;
    width: 100%;
  }
`

export const ListingCardContent = styled.div`
  padding: 24px 32px 24px 0px;
  flex: 1;

  .listing_group {
    margin-bottom: 18px;
  }
`

export const ListingCardAddress = styled.h3`
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 1.6px;
  margin-bottom: 18px;

  a {
    color: ${COLORS.secondary};
  }
`

export const ListingCardFeatures = styled.div`
  display: flex;
  align-items: center;
`

export const ListingCardFeature = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS.secondary};
  margin-right: 22px;
  transition: all 0.25s ease-in;
`

export const ListingCardFeatureLabel = styled.span`
  margin-right: 8px;
  font-family: ${FONTS.medium};
`

export const ListingCardFooter = styled.div`
  position: relative;

  svg {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    color: ${COLORS.secondary};
    cursor: pointer;
  }
`

export const ListingCardPrice = styled.span`
  color: ${COLORS.secondary};
  font-family: ${FONTS.medium};
`

export const ListingCard = styled.div`
  display: flex;
  background-color: ${COLORS.nature[95]};
  align-items: center;
  margin-bottom: 40px;
  width: 100%;
  border-radius: 0px 0px 60px 0px;

  &:hover {
    ${ListingCardImage} img {
      transform: scale(1.1);
    }
  }

  @media ${DEVICES.mobile} {
    align-items: unset;
    flex-direction: column;
    border-radius: 0 0 20px 0;

    ${ListingCardImage} {
      width: 100%;
      border-radius: unset;
    }

    ${ListingCardContent} {
      padding: 24px 20px;

      .listing_group {
        margin-bottom: 28px;
        > div:first-child {
          margin-bottom: 12px;
        }
      }
    }

    ${ListingCardAddress} {
      font-size: 16px;
      line-height: 22px;
    }

    ${ListingCardPrice} {
      color: ${COLORS.navi};
    }

    ${ListingCardFooter} {
      svg {
        display: none;
      }
    }
  }
`
