import { DEVICES } from '@constants/global'
import { COLORS } from '@styles/colors'
import { FONTS, text2XL, textBase, textLG, text4XL, textXL } from '@styles/fonts'
import styled from 'styled-components'

export const ErrorsContainer = styled.section`
  display: flex;
  background: ${COLORS.nature.base};
  height: 100vh;
  align-items: center;
  padding-left: 58px;
  padding-right: 78px;

  &::before {
    position: fixed;
    content: '';
    width: calc((100% - 1920px) / 2);
    height: 100%;
    right: 1px;
    top: 0;
    background: ${COLORS.nature.base};
    z-index: 10;
  }

  @media ${DEVICES.mobile} {
    align-items: center;
    padding: 28px;
    height: auto;
  }

  @media ${DEVICES.tablet} {
    align-items: center;
    padding-left: 28px;
    padding-right: 28px;
  }
`

export const ErrorLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;

  @media ${DEVICES.mobile} {
    width: 100%;
    align-items: center;
    gap: 6px;
    text-align: center;
  }
`

export const ErrorTitle = styled.h2`
  ${text4XL}
  color: ${COLORS.secondary};

  font-family: ${FONTS.bold};
  font-weight: 450;
  text-transform: uppercase;
  margin-bottom: 8px;

  @media ${DEVICES.mobile} {
    ${text2XL}
    text-align: center;
    font-weight: 450;
    letter-spacing: 1.6px;

    margin-bottom: 2px;
  }
`

export const ErrorSubTitle = styled.span`
  ${textLG}
  color: ${COLORS.secondary};
  font-family: ${FONTS.medium};
  font-weight: 420;
  letter-spacing: 0.1px;
`

export const ErrorDescription = styled.span`
  ${textBase};
  color: ${COLORS.secondary};
  font-family: ${FONTS.regular};
`


export const ErrorButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 24px;

  @media ${DEVICES.mobile} {
    margin-bottom: 30px;
  }
`

export const ErrorRightContainer = styled.div`
  display: flex;
  align-items: center;

  @media ${DEVICES.mobile} {
    width: 100%;
    justify-content: center;
    margin-top: 60px;
    margin-bottom: 60px;
  }
`

export const BreakLine = styled.br`

  @media ${DEVICES.mobile} {
    display: none;
  }
`

export const ModalBody = styled.div`
  padding: 40px 35px 32px 35px;
  background: ${COLORS.nature[95]};

  @media ${DEVICES.mobile} {
    padding: 28px 20px 40px 20px;
  }
`

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`


export const Message = styled.span`
  ${textXL};
  color: ${COLORS.secondary};
  //text-transform: initial;
`

export const MessageWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
`


