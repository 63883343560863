import AgentCard from '@global/AgentCard'
import { Col, Row } from '@styles/global.styled'
import { useState } from 'react'
import dynamic from 'next/dynamic'
import { useEnquiryFormContext } from '@context/EnquiryFormContext'

const EnquiryForm = dynamic(() => import('@global/Form/EnquiryForm'), {
  ssr: false,
})

const AgentCardGrid = ({ agents }) => {
  const { setShowEnquiryForm } = useEnquiryFormContext()
  const [selectedAgent, setSelectedAgent] = useState(null)

  if (!agents?.length) return null

  const onClickEnquire = (agent) => {
    setSelectedAgent(agent)
    setShowEnquiryForm(true)
  }

  return (
    <>
      <Row gutter={15}>
        {agents.map((agent, i) => (
          <Col md={4} key={`agent-${i}`}>
            <AgentCard
              {...AgentCard.ApiDataToProps(agent)}
              isLazyLoad={i < 3 ? false : true}
              onClickEnquire={onClickEnquire}
            />
          </Col>
        ))}
      </Row>
      {selectedAgent && (
        <EnquiryForm
          type='agent'
          agentName={selectedAgent.name}
          agentSlug={selectedAgent.slug}
          headerInfo={{
            image: selectedAgent.photo,
            title: selectedAgent.name,
            description: selectedAgent.job_title,
            id: selectedAgent.id,
          }}
        />
      )}
    </>
  )
}

AgentCardGrid.defaultProps = {
  agents: [],
}

export default AgentCardGrid
