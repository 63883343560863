const IconLockOutline = (props) => {
  return (
    <svg
      width='21'
      height='20'
      viewBox='0 0 21 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M7 9.16667V5.83333C7 4.94928 7.35119 4.10143 7.97631 3.47631C8.60143 2.85119 9.44928 2.5 10.3333 2.5C11.2174 2.5 12.0652 2.85119 12.6904 3.47631C13.3155 4.10143 13.6667 4.94928 13.6667 5.83333V9.16667M4.5 10.8333C4.5 10.3913 4.67559 9.96738 4.98816 9.65482C5.30072 9.34226 5.72464 9.16667 6.16667 9.16667H14.5C14.942 9.16667 15.366 9.34226 15.6785 9.65482C15.9911 9.96738 16.1667 10.3913 16.1667 10.8333V15.8333C16.1667 16.2754 15.9911 16.6993 15.6785 17.0118C15.366 17.3244 14.942 17.5 14.5 17.5H6.16667C5.72464 17.5 5.30072 17.3244 4.98816 17.0118C4.67559 16.6993 4.5 16.2754 4.5 15.8333V10.8333ZM9.5 13.3333C9.5 13.5543 9.5878 13.7663 9.74408 13.9226C9.90036 14.0789 10.1123 14.1667 10.3333 14.1667C10.5543 14.1667 10.7663 14.0789 10.9226 13.9226C11.0789 13.7663 11.1667 13.5543 11.1667 13.3333C11.1667 13.1123 11.0789 12.9004 10.9226 12.7441C10.7663 12.5878 10.5543 12.5 10.3333 12.5C10.1123 12.5 9.90036 12.5878 9.74408 12.7441C9.5878 12.9004 9.5 13.1123 9.5 13.3333Z'
        stroke='#F92044'
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default IconLockOutline
