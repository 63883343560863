import { listSearchPageType } from '@lib/variables'
import { sortAuctions, sortInspections, sortSetDate } from '@lib/helpers/search'
import NoResults from '../NoResults'
import ListingCardHorizontal from '@global/ListingCard/Horizontal'

const SearchResultDateGroup = ({ items, page_type }) => {
  let data = []
  if (page_type == listSearchPageType.SEARCH_TYPE_AUCTION) {
    data = sortAuctions(items)
  } else if (page_type == listSearchPageType.SEARCH_TYPE_INSPECTION) {
    data = sortInspections(items)
  } else if (page_type == listSearchPageType.SEARCH_TYPE_SALEDATE) {
    data = sortSetDate(items)
  }

  return (
    <div>
      {items.length > 0 ? (
        items.map((item, i) => (
          <ListingCardHorizontal
            {...ListingCardHorizontal.ApiDataToProps(item)}
            key={`listing-${i}`}
            showType={page_type}
            isAuctionView={page_type == listSearchPageType.SEARCH_TYPE_AUCTION}
          />
        ))
      ) : (
        <NoResults isAuction pageType={page_type} />
      )}
    </div>
  )
}

SearchResultDateGroup.defaultProps = {
  items: [],
  metaData: {},
}

export default SearchResultDateGroup
