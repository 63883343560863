import React, { useState, useEffect } from 'react'
import IconArrowDown from '@icons/ArrowDown'
import * as S from './BackToTop.styled'

const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false)

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 500) {
        setIsVisible(true)
      } else {
        setIsVisible(false)
      }
    }

    window.addEventListener('scroll', toggleVisibility)

    return () => window.removeEventListener('scroll', toggleVisibility)
  }, [])

  return (
    isVisible && (
      <S.BackToTop onClick={scrollToTop}>
        <IconArrowDown />
      </S.BackToTop>
    )
  )
}

export default BackToTop
