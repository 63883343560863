import HeroSection from '../components/global/Blocks/Hero'
import CtaBlocks from '../components/SuburbProfiles/RegionList'
import WagtailBlocksSection from '../components/global/Blocks/WagtailBlocksSection'
import { getChildPages } from '../lib/api-services/cmsService'
import { apiFieldsQuery, itemShowPerPage } from '../lib/variables'

const LifestyleCategoryIndex = ({
  region,
  pageData: {
    title,
    title_colour,
    hero_image,
    content_blocks,
    hero_image_anchor,
  },
}) => {
  return (
    <>
      <div>
        <HeroSection
          titleColour={title_colour}
          title={title}
          backgroundImageUrl={hero_image && hero_image.url}
          imagePosition={hero_image_anchor}
        />
      </div>
      {region.blocks.length > 0 && (
        <div className='block-component'>
          <CtaBlocks {...region} />
        </div>
      )}
      <WagtailBlocksSection blocks={content_blocks} />
    </>
  )
}
LifestyleCategoryIndex.getInitialProps = async (ctx, pageData) => {
  const childrenPages = await getChildPages(
    'suburbs.LifestyleCategoryPage',
    pageData.id,
    itemShowPerPage.LifestyleCategoryIndex,
    null,
    null,
    apiFieldsQuery.LifestyleCategoryPage
  )
  return {
    region: {
      title: 'Suburb profile by lifestyle',
      blocks: childrenPages.items
        ? childrenPages.items.map((item) => {
            return {
              copy: item.hover_text,
              title: item.title,
              image: item.hero_image_small && item.hero_image_small.url,
              link: item.meta.path,
              color: 'white',
            }
          })
        : [],
    },
  }
}
export default LifestyleCategoryIndex
