const IconArrowDown = (props) => {
  return (
    <svg
      width='12'
      height='7'
      viewBox='0 0 12 7'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M6.04004 6.33533L0.540039 1.24699L1.52546 0.335327L6.04004 4.53321L10.5546 0.356528L11.54 1.26819L6.04004 6.33533Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default IconArrowDown
