import {
  Col,
  Container,
  Content,
  Row,
  Section,
  Title,
  TitleContent,
} from '@styles/global.styled'
import ShareButton from '@global/ShareButton'
import ContactCard from '@global/ContactCard'
import { formatDate } from '@lib/date'
import {
  SidebarGroup,
  SidebarGroupTitle,
  AwardInfo,
  AwardInfoDate,
} from './AwardDetails.styled'

const AwardDetails = ({
  title,
  content,
  date,
  agents,
  offices,
  shareTitle,
  socialLinks,
}) => {
  return (
    <Container>
      <Row gutter={20} mbGutter={0}>
        <Col lg={8}>
          <TitleContent>
            <Title>{title}</Title>
            <Content dangerouslySetInnerHTML={{ __html: content }} />
          </TitleContent>
        </Col>
        <Col lg={4}>
          <AwardInfo>
            <AwardInfoDate>
              <span>AWARDED</span> {formatDate(date, 'dd MMMM yyyy')}
            </AwardInfoDate>
            <ShareButton
              title={shareTitle}
              shareInfo={{
                quote: content,
                subject: { title },
              }}
            />
          </AwardInfo>
          {agents?.length > 0 && (
            <SidebarGroup>
              <SidebarGroupTitle>Agents</SidebarGroupTitle>
              {agents.map((agent, key) => (
                <ContactCard {...agent} key={`agent-${key}`} />
              ))}
            </SidebarGroup>
          )}

          {offices?.length > 0 && (
            <SidebarGroup>
              <SidebarGroupTitle>Offices</SidebarGroupTitle>
              {offices.map((office, key) => (
                <ContactCard
                  key={`agent-${key}`}
                  {...office}
                  name={
                    <>
                      Barry Plant
                      <br />
                      {office.name}
                    </>
                  }
                />
              ))}
            </SidebarGroup>
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default AwardDetails
