import { COLORS } from '@styles/colors'
import { FONTS, textBase, textLG } from '@styles/fonts'
import styled from 'styled-components'
import Image from '@global/Image'

export const TestimonialCard = styled.div`
  margin-bottom: 48px;
`

export const TestimonialCardImage = styled.div`
  margin-bottom: 24px;
  height: 0;
  padding-top: 74%;
  position: relative;
`

export const TestimonialImage = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const TestimonialCardBody = styled.div``

export const TestimonialTitle = styled.h4`
  ${textBase};
  color: ${COLORS.secondary};
  font-family: ${FONTS.bold};
  margin-bottom: 24px;
`

export const  TestimonialDescription = styled.p`
  ${textBase};
  color: ${COLORS.navi};
  text-transform: unset;
  font-family: ${FONTS.regular};
`

export const  TestimonialOffices = styled.span`
   ${textBase};
   color: ${COLORS.secondary};
   text-transform: uppercase;
   font-family: ${FONTS.regular};
  margin-bottom: 4px;
`
