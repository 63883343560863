import { NextSeo } from 'next-seo'
import { Feed } from 'feed'
import { WagtailMetaToNextSEO } from '../lib/api'
import { getWagtailPageBySlug } from '../lib/api-services/cmsPageService'
import { getRedirects } from '../lib/api-services/redirectsService'
import Layouts from '../layouts'
import { stripQueryParam } from '../lib/helpers'
import { rssFeedData, domain } from '../lib/variables'
import { useContext } from 'react'
import { OfficeSiteContext } from '../context/OfficeSiteContext'
import AnnouncementBar from '@components/Offices/AnnouncementBar'
import Error from './_error'
import BackToTop from '@global/BackToTop'

const isShowBackToTop = (wagtailPageType) => {
  return [
    'ListingSearchPage',
    'OfficeIndexPage',
    'AgentIndexPage',
    'CareerIndexPage',
    'ProjectLandingIndex',
  ].includes(wagtailPageType)
}

export const WagtailPages = {
  // Wagtail page type -> page component mapping where they don't map 1-1
  BlogIndex: Layouts.BlogIndexPage,
  Error: Layouts.ErrorPage,
  iFramePage: Layouts.IframePage,
}

const WagtailPage = ({ pageData, wagtailPageType, pageProps, SEO }) => {
  // Update the nav and site header context based on the wagtail page
  const { currentSite, enableSite, resetSite } = useContext(OfficeSiteContext)

  if (pageProps && 400 < pageProps.statusCode && pageProps.statusCode < 600) {
    return <Error pageData={pageProps} />
  }

  let Page = Layouts[wagtailPageType] || WagtailPages[wagtailPageType]
  if (!Page) {
    Page = Layouts.GenericPageTemplate
  }

  let announcement = undefined
  if (pageData.meta) {
    if (!pageData.meta.office_ancestor && currentSite.officeId) {
      resetSite()
    } else if (
      pageData.meta.office_ancestor &&
      currentSite.officePath != pageData.meta.office_ancestor.path
    ) {
      const { id, title, path, search_settings } = pageData.meta.office_ancestor
      enableSite(id, title, path, search_settings)
    }

    const { meta } = pageData
    if (meta.office_ancestor) {
      const { office_ancestor } = meta
      const { important_notice } = office_ancestor
      if (
        important_notice?.show_on_child_pages === true ||
        (important_notice?.show_on_child_pages === false &&
          meta.path === office_ancestor.path)
      ) {
        announcement = {
          title: null,
          content: [important_notice.message],
          link: null,
        }
      }
    }
  }

  return (
    <>
      {SEO && <NextSeo {...SEO} />}
      {pageData.meta && pageData.meta.structured_data && (
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(pageData.meta.structured_data),
          }}
        />
      )}
      {announcement && <AnnouncementBar {...announcement} />}
      <Page pageData={pageData} {...pageProps} />
      {isShowBackToTop(wagtailPageType) && <BackToTop />}
    </>
  )
}

WagtailPage.getInitialProps = async (ctx) => {
  const slug = ctx.query && ctx.query.slug ? ctx.query.slug : '/'
  // We want an object of the non-slug query to pass queryParams to the backend
  const queryParams = stripQueryParam(ctx.query, 'slug')

  const { pageData, wagtailPageType } = await getWagtailPageBySlug(
    slug,
    queryParams
  )

  const { res } = ctx
  if (res) {
    res.statusCode = pageData.responseStatus || pageData.status
  }

  if (wagtailPageType) {
    const PageComponent =
      Layouts[wagtailPageType] ||
      WagtailPages[wagtailPageType] ||
      Layouts.GenericPageTemplate
    let pageProps = { ...ctx.query }
    if (PageComponent.getInitialProps) {
      pageProps = {
        ...pageProps,
        ...(await PageComponent.getInitialProps(ctx, pageData)),
      }
    }
    const SEO = WagtailMetaToNextSEO(pageData, pageData.meta)

    if (queryParams.rss && PageComponent.getRSSFeedItems) {
      // Try and render an RSS feed response. Checks for Component.getRSSFeedItems which should return
      // an array of RSS Feed objects
      const rssItems = PageComponent.getRSSFeedItems(pageData, pageProps)
      const { res, req } = ctx
      res.writeHead(200, { 'Content-Type': 'text/xml' })
      const feed = new Feed({
        ...rssFeedData,
        link: `${domain}${req.originalUrl}`,
      })
      rssItems &&
        rssItems.length > 0 &&
        rssItems.map((item) => {
          feed.addItem(item)
        })
      res.write(feed.rss2())
      res.end()
    }
    pageProps.statusCode = pageData.responseStatus
      ? pageData.responseStatus
      : pageData.status
    return { pageData, wagtailPageType, pageProps, SEO }
  } else {
    if (pageData.responseStatus == 404 || pageData.status == 404) {
      // On a 404 we want to check for a redirect in the CMS
      const redirectsResponse = await getRedirects(`/${slug}`)
      if (
        redirectsResponse &&
        redirectsResponse.results &&
        redirectsResponse.results.length > 0 &&
        ctx.res
      ) {
        const redirect = redirectsResponse.results[0]
        ctx.res.writeHead(redirect.is_permanent ? 301 : 302, {
          Location: redirect.link,
        })
        ctx.res.end()
      }

      // No redirect found - through a 404
      return {
        pageProps: {
          statusCode: 404,
          statusText: pageData.statusText,
        },
      }
    } else if (pageData.responseStatus == 500 || pageData.status == 500) {
      return {
        pageProps: {
          statusCode: 500,
          statusText: pageData.statusText,
        },
      }
    } else {
      return { pageData, wagtailPageType: 'Error' }
    }
  }
}

export default WagtailPage
