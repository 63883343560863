import * as S from './NewsListWithCards.styled'
import ArticleCard from '@global/ArticleCard'
import { Col, Row } from '@styles/global.styled'

const NewsListWithCards = ({items}) => {

  return (
    <S.NewsListWrap>
      <Row gutter={15}>
        {items &&
          items.map((post, index) => (
            <Col md={4} key={index}>
              <ArticleCard key={index} article={post} />
            </Col>
          ))}
      </Row>
    </S.NewsListWrap>
  )
}

export default NewsListWithCards
