import React, { useState } from 'react'
import { getTestimonials } from '@lib/api-services/testimonialService'
import WagtailBlocksSection from '../components/global/Blocks/WagtailBlocksSection'
import HeroSection from '../components/global/Blocks/Hero'
import { itemShowPerPage } from '@lib/variables'
import { Col, Container, Row, Section } from '@styles/global.styled'
import FullWidthText from '../components/global/Blocks/FullWidthText'
import TestimonialCard from '@global/TestimonialCard'
import LoadmoreInfo from '@global/SearchResults/LoadmoreInfo'

const formatTestimonial = (data) => {
  return {
    id: data.id,
    slug: data.slug,
    name: data.name,
    headline: data.headline,
    testimonial_type: data.testimonial_type,
    image: data.image,
    image_thumbnail: data.image_thumbnail,
    star_rating: data.star_rating,
    show_star_rating: data.show_star_rating,
    comments: data.comments,
    offices: data.offices?.map((office) => office.office.title),
    agents: data.agents?.map((agent) => agent.agent),
  }
}

const TestimonialIndexPage = ({ office, pageData, childOf, ...props }) => {
  const { title, hero_image, hero_text, preamble, hero_image_anchor } = pageData
  const [testimonials, setTestimonials] = useState({ ...props.testimonials })

  const [queryParams, setQueryParams] = useState({
    child_of: childOf,
    page: 1,
    per_page: itemShowPerPage.TestimonialIndexPage,
  })

  const fetchTestimonials = async (params) => {
    const modifiedParams = {
      ...queryParams,
      page: params.page,
    }
    setQueryParams(modifiedParams)

    try {
      const response = await getTestimonials(queryParams)
      return {
        count: response.count,
        results: response.results?.map(formatTestimonial),
      }
    } catch (error) {
      console.error('Failed to fetch testimonials:', error)
    }
  }

  return (
    <>
      {hero_image && (
        <HeroSection
          title={title}
          bgImageUrl={hero_image && hero_image.url}
          bgPosition={hero_image_anchor}
          titlePosition='bottom'
        />
      )}

      <Section>
        <FullWidthText title={hero_text} para={preamble} />
      </Section>

      <Section>
        <Container>
          <Row gutter={15}>
            {testimonials &&
              testimonials.results?.map((testimonial) => (
                <Col md={4} key={testimonial.id}>
                  <TestimonialCard testimonial={testimonial} />
                </Col>
              ))}
          </Row>

          {testimonials.count > 0 && (
            <LoadmoreInfo
              total={testimonials.count}
              numberShowing={
                queryParams.page * itemShowPerPage.TestimonialIndexPage
              }
              queryParams={queryParams}
              apiCall={fetchTestimonials}
              page={queryParams.page}
              onSuccess={(data) => {
                setTestimonials((prevTestimonials) => {
                  return {
                    ...prevTestimonials,
                    results: prevTestimonials.results.concat(data),
                  }
                })
              }}
            />
          )}
        </Container>
      </Section>

      <WagtailBlocksSection blocks={pageData.content_blocks} />
    </>
  )
}

TestimonialIndexPage.getInitialProps = async (ctx, pageData) => {
  let query = ctx.query
  const slug = query.slug
  delete query.slug
  const queryParams = { ...query }

  const office = pageData.meta.office_ancestor
  query.child_of = pageData.id
  if (query.keyword) {
    query.search = query.keyword
    delete query.keyword
  }

  if (query.offices__office) {
    query.child_of = null
  }

  if (office) {
    query.offices__office = office.id
  }

  const params =
    {
      ...query,
      per_page: itemShowPerPage.TestimonialIndexPage,
      ordering: '-date',
    } || {}

  const testimonials = await getTestimonials(params)

  return {
    office: pageData.meta.office_ancestor,
    slug,
    childOf: query.child_of,
    queryParams: queryParams,
    testimonials: {
      count: testimonials.count,
      results: testimonials.results?.map(formatTestimonial),
    },
  }
}
export default TestimonialIndexPage
