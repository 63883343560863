import { Col, Container, Row, Section } from '@styles/global.styled'
import ContactCard from '@global/ContactCard'
import Button from '@global/Button'
import ShareButton from '@global/ShareButton'
import * as S from './CarrerDetails.styled'
import { useFormModalContext } from '@context/FormModalContext'

const CarrerDetails = ({ title, description, office }) => {
  const { setShowFormModal } = useFormModalContext()
  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setShowFormModal(true)
  }

  return (
    <Section paddingSmall>
      <Container>
        <Row>
          <Col lg={8}>
            <S.CarrerDetails>
              <S.CarrerDescription
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </S.CarrerDetails>
          </Col>
          <Col lg={4}>
            <S.RightInfo>
              {office && (
                <S.CarrerOffice>
                  <h4>Office Contact</h4>
                  <ContactCard
                    name={
                      <>
                        Barry Plant
                        <br /> {office.title}
                      </>
                    }
                    phone={office.phone}
                    image={office.results_image_small}
                    link={office.path}
                  />
                </S.CarrerOffice>
              )}

              <S.CarrerActions>
                <Button color='secondary' block onClick={handleClick}>
                  Apply for this position
                </Button>
                <ShareButton
                  button
                  shareInfo={{
                    quote: title + ': ' + description,
                    subject: 'Check out this job at Barry Plant',
                  }}
                />
              </S.CarrerActions>
            </S.RightInfo>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}
export default CarrerDetails
