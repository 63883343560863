import HeroSection from '../components/global/Blocks/Hero'
import WagtailBlocksSection from '../components/global/Blocks/WagtailBlocksSection'
import { useEffect, useState } from 'react'
import dynamic from 'next/dynamic'

const DynamicForm = dynamic(() => import('@global/Form/DynamicForm'), {
  ssr: false,
})

const FloatingFormPage = ({ pageData }) => {
  const { content_blocks, hero_panel, latest_news, form } = pageData
  const heroData = hero_panel.length > 0 && hero_panel[0].value
  const [finalContentBlocks, setFinalContentBlocks] = useState([])

  content_blocks[0].className = 'u-show@desktop'

  useEffect(() => {
    const hasLatestNews = content_blocks.filter((x) => x.type == 'latest_news')
    if (!hasLatestNews.length) {
      latest_news.forEach((data) => {
        if (data.type == 'latest_news_panel') {
          data.type = 'latest_news'
        }
        data.value.latest_news.forEach((item) => {
          item.link = {
            label: 'read article',
            url: item.path,
          }
        })
      })
      finalContentBlocks.push(latest_news[0])
    } else {
      finalContentBlocks.forEach((data) => {
        if (data.type == 'latest_news') {
          data.value.latest_news.forEach((item) => {
            item.link = {
              label: 'read article',
              url: item.path,
            }
          })
        }
      })
    }

    setFinalContentBlocks([...content_blocks.concat(...finalContentBlocks)])
  }, [])

  return (
    <div>
      <HeroSection
        titleColour={heroData.heading_colour}
        title={heroData.heading}
        backgroundImageUrl={heroData.background_image}
        imagePosition={heroData.image_position}
        floatingForm
      >
        <div>
          {form.map(({ value }) => (
            <DynamicForm
              key={value.slug}
              layout={value.layout}
              slug={value.slug}
            />
          ))}
        </div>
      </HeroSection>
      <div>
        <WagtailBlocksSection
          blocks={[{ ...content_blocks[0], className: '' }]}
        />
        {form.map(({ value }) => (
          <DynamicForm
            key={value.slug}
            layout={value.layout}
            slug={value.slug}
          />
        ))}
      </div>
      <WagtailBlocksSection blocks={finalContentBlocks} />
    </div>
  )
}

export default FloatingFormPage
