import { DEVICES } from '@constants/global'
import { COLORS } from '@styles/colors'
import { FONTS, text2XL } from '@styles/fonts'
import styled from 'styled-components'

export const Thankyou = styled.div``

export const ThankyouTitle = styled.h2`
  margin-bottom: 32px;
  text-transform: none;
  font-family: ${FONTS.medium};
`

export const ThankyouSubTitle = styled.h3`
  ${text2XL};
  margin-bottom: 40px;
  text-transform: none;
  font-family: ${FONTS.medium};
`

export const ThankyouContent = styled.div`
  color: ${COLORS.secondary};

  p {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`
