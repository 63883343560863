import { DEVICES } from "@constants/global";
import { COLORS } from "@styles/colors";
import { FONTS, text2XL, text3XL, text4XL } from "@styles/fonts";
import styled from "styled-components";

export const BlogDetailWrap = styled.div`
    section:has(div.blog-paragraph) {
      padding-top: 40px;
    }
`

export const BlogTitle = styled.h2`
  ${text4XL};
  color: ${COLORS.secondary};
  font-family: ${FONTS.bold};
  margin-bottom: 64px;

  @media ${DEVICES.mobile} {
    ${text2XL};
    color: ${COLORS.secondary};
    font-family: ${FONTS.medium};
  }
`

export const BlogPreamble = styled.h3`
  ${text3XL};
  color: ${COLORS.secondary};
  font-family: ${FONTS.medium};
  text-transform: initial;

  @media ${DEVICES.mobile} {
    ${text2XL};
    color: ${COLORS.secondary};
    font-family: ${FONTS.regular};
    padding-top: 72px;
  }
`

export const BlogSidebar = styled.div`
    display: none;

  @media ${DEVICES.mobile} {
    display: block;
  }
`
