import WagtailBlocksSection from '@global/Blocks/WagtailBlocksSection'
import { Container, Section } from '@styles/global.styled'
import JobResults from '@components/Careers/JobResults'
import { useFormModalContext } from '@context/FormModalContext'
import Hero from '@global/Blocks/Hero'
import dynamic from 'next/dynamic'

const CareerRegisterForm = dynamic(
  () => import('@global/Form/CareerRegisterForm'),
  {
    ssr: false,
  }
)

const CareersLandingPage = (props) => {
  const { setShowFormModal } = useFormModalContext()
  const {
    title,
    hero_image,
    content_blocks,
    id,
    hero_image_anchor,
    total_jobs,
  } = props.pageData

  // Handle View Positions
  content_blocks.forEach((it) => {
    if (it.type === 'centered_image_and_text') {
      if (it.value.call_to_action_text === 'View Positions Available') {
        it.value.call_to_action_click = () => {
          const elm = document.getElementById('vacant-showcase')
          elm.scrollIntoView({ behavior: 'smooth' })
        }
      }
      if (it.value.call_to_action_text === 'Register Interest') {
        it.value.call_to_action_click = () => {
          setShowFormModal(true)
        }
      }
    }
  })

  return (
    <React.Fragment>
      <Hero
        title={title}
        bgImageUrl={hero_image.url}
        titlePosition='bottom'
        bgPosition={hero_image_anchor}
      />
      <WagtailBlocksSection blocks={content_blocks} />
      <CareerRegisterForm />
    </React.Fragment>
  )
}

export default CareersLandingPage
