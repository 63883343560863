import { formatDate } from '@lib/date'
import * as S from './BlogSidebar.styled'
import ShareButton from '@global/ShareButton'
import FavouriteHeart from '@global/FavouriteHeart'
import BlogAuthor from '@components/blog/BlogAuthor'

const BlogSidebar = (props) => {
  const { authors, postedDate, showDate, headerImage, id, url, title, categoryData } = props

  const agentMap = new Map();
  const officeMap = new Map();

  authors.forEach(item => {
    const { agent, office } = item;

    if (agent) {
      const { id, title, job_title, profile_image_thumbnail, url } = agent
      agentMap.set(id, {
        id,
        name: title,
        subTitle: job_title,
        url,
        photo: profile_image_thumbnail ? profile_image_thumbnail.url : null
      })
    }

    if (office) {
      const { id, path, office_name, results_image_small } = office;
      officeMap.set(id, { id, office_name,
        name: `Barry Plant ${office_name}`,
        url: path,
        photo: results_image_small
      })
    }
  })

  const agents = [...agentMap.values()];
  const offices = [...officeMap.values()];

  return (
    <S.BlogSidebarWrap>
      <S.BlogSidebarInfo>
        {categoryData && (
          <S.BlogSidebarCategory>{categoryData.category}</S.BlogSidebarCategory>
        )}
        {showDate && postedDate && (
          <S.BlogSidebarDate>
            {formatDate(postedDate, 'dd MMMM yyyy')}
          </S.BlogSidebarDate>
        )}
      </S.BlogSidebarInfo>
      <S.BlogSidebarShare>
        <ShareButton
          showInfo
          title='Share'
          modalPosition='right'
          heading= 'SHARE THIS ARTICLE'
          shareInfo={{
            title: title,
            url: url,
            quote: title,
            media: headerImage,
            image: headerImage,

          }}          
        />
        <S.BlogSidebarFavourite>
          <FavouriteHeart text type='post' id={id} inline label={'Save Article'} label_added='Remove Article'/>
        </S.BlogSidebarFavourite>
      </S.BlogSidebarShare>

      <S.BlogSidebarAuthor>
        {authors?.length > 0 && (
          <>
            {agents?.length > 0 && (
              <S.AuthorWrap>
                <S.BlogSidebarAuthorTitle>{agents.length > 1 ? 'Authors' : 'Author'}</S.BlogSidebarAuthorTitle>
                {agents.map((it, key) => <BlogAuthor key={key} data={it} />)}
              </S.AuthorWrap>
            )}

            {offices?.length > 0 && (
              <S.AuthorWrap>
                <S.BlogSidebarAuthorTitle>{offices.length > 1 ? 'Offices' : 'Office'}</S.BlogSidebarAuthorTitle>
                {offices.map((it, key) => <BlogAuthor key={key} data={it} />)}
              </S.AuthorWrap>
            )}
          </>
        )}
      </S.BlogSidebarAuthor>
    </S.BlogSidebarWrap>
  )
}

BlogSidebar.defaultProps = {
  authors: []
}

export default BlogSidebar
