import Select from '@global/FormInput/Select'
import * as S from './SearchResultsCount.styled'

const SearchResultsCount = ({ total, numberShowing, select }) => {
  return total > 0 ? (
    <S.SearchResultsCount>
      {numberShowing && (
        <span>1-{total < numberShowing ? total : numberShowing} of&nbsp;</span>
      )}
      <span>{total} results</span>

      {select?.options && (
        <S.SelectGroup>
          <span>Sort by</span>
          <Select
            defaultValue={
              select.options.find((it) => it.value === select.value) ?? null
            }
            options={select.options}
            onChange={(option) => select.onChange(option.value)}
          />
        </S.SelectGroup>
      )}
    </S.SearchResultsCount>
  ) : null
}

export default SearchResultsCount
