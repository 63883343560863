import * as S from './ResourceList.styled'
import { Col, Row } from '@styles/global.styled'
import PostCard from '@global/PostCard'
const ResourceList = ({items}) => {

  return (
    <S.ResourceListWrap>
      <Row gutter={15}>
        {items &&
          items.map((post, index) => (
            <Col key={index} lg={4} md={6} flex>
              <PostCard
                key={index}
                heading={post.title}
                imgUrl={post.imgUrl}
                link={post.link}
                content={post.description}/>
            </Col>
          ))}
      </Row>
    </S.ResourceListWrap>
  )
}

export default ResourceList
