import { DEVICES } from '@constants/global'
import { COLORS } from '@styles/colors'
import { FONTS, text2XL } from '@styles/fonts'
import styled from 'styled-components'

export const SuburbInfo = styled.div`
  width: 58%;

  @media ${DEVICES.tablet} {
    width: 100%;
  }
`

export const SuburbInfoTitle = styled.h2`
  margin-bottom: 32px;
`

export const SuburbInfoPreamble = styled.div`
  ${text2XL};
  color: ${COLORS.secondary};
  font-family: ${FONTS.medium};
`
