import styled, { css } from 'styled-components'
import { COLORS } from '@styles/colors'
import { DEVICES } from '@constants/global'
import { FONTS } from '@styles/fonts'

export const AgentFiltersForm = styled.div`
  padding: 16px 24px 24px;
  background-color: ${COLORS.nature[95]};
  width: 100%;
  z-index: 10;
  max-width: 830px;
  display: none;

  label {
    color: ${COLORS.secondary};
    font-family: ${FONTS.medium};
  }

  ${({ open }) =>
    open &&
    css`
      display: block;
    `}

  button {
    display: none;
  }

  @media ${DEVICES.mobile} {
    padding: 0 16px 28px;

    > div {
      padding-bottom: 10px;
    }

    button {
      display: block;
      width: 100%;
    }
  }
`
