import styled, { css } from 'styled-components'
import { COLORS } from '@styles/colors'
import { textSM } from '@styles/fonts'
import { DEVICES } from '@constants/global'

export const AnnouncementBar = styled.div`
  background: ${COLORS.secondary};
  position: sticky;
  top: 0;
  z-index: 1000;
  padding: 8px 20px;
  display: flex;
  align-items: center;

  svg {
    color: ${COLORS.white};
    flex: 0 0 20px;
    margin-left: auto;
  }

  @media ${DEVICES.mobile} {
    position: relative;
  }
`

export const AnnouncementBarContent = styled.div`
  ${textSM};
  color: ${COLORS.white};
  margin-right: 20px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${COLORS.white};
  }

  a {
    color: ${COLORS.white};
    text-decoration: underline;
  }
`
