import * as S from './SuburbContent.styled'
import { Container } from '@styles/global.styled'
import ReadMoreReadLess from '@global/ReadMoreReadLess'

const SuburbContent = ({ content }) => {
  return (
    <Container style={{ marginTop: '30px' }}>
      <S.SuburbContent>
        <ReadMoreReadLess
          style={{ marginBottom: '0' }}
          content={content}
          minLines={20}
          defaultMaxHeight={500}
        />
      </S.SuburbContent>
    </Container>
  )
}

export default SuburbContent
