import WagtailBlocksSection from '@global/Blocks/WagtailBlocksSection'
import { itemShowPerPage, apiFieldsQuery } from '@lib/variables'
import { truncateText, stripTags } from '@lib/helpers'
import dynamic from 'next/dynamic'
import { Container } from '@styles/global.styled'
import Breadcrumbs from '@global/Breadcrumbs'
import { getOffices } from '@lib/api-services/officesService'
import { getChildPages } from '@lib/api-services/cmsService'
import { useFormModalContext } from '@context/FormModalContext'
import JobResults from '@components/Careers/JobResults'

const CareerRegisterForm = dynamic(
  () => import('@global/Form/CareerRegisterForm'),
  { ssr: false }
)

const formatJobItems = (items) =>
  items.map((item) => ({
    name: item.title,
    location: item.location?.title,
    type: item.get_job_type_display,
    description: truncateText(stripTags(item.description), 100, '…'),
    link: item.meta.path,
  }))

const CareerIndexPage = (props) => {
  const { office, searchFields, pageData, parentId, jobs } = props
  const { setShowFormModal } = useFormModalContext()

  // Handle View Positions
  pageData.content_blocks?.forEach((it) => {
    if (it.type === 'centered_image_and_text') {
      if (it.value.call_to_action_text === 'Register Interest') {
        it.value.call_to_action_click = () => {
          setShowFormModal(true)
        }
      }
    }
  })

  return (
    <>
      <Container>
        <Breadcrumbs
          breadcrumbs={pageData.meta.breadcrumbs?.map((it) => ({
            ...it,
            label: it.title,
          }))}
        />
        <JobResults
          parentId={parentId}
          jobs={jobs}
          searchFields={searchFields}
        />
      </Container>
      <CareerRegisterForm office={office} />
      <WagtailBlocksSection blocks={pageData.content_blocks} />
    </>
  )
}

CareerIndexPage.getInitialProps = async (ctx, pageData) => {
  const parentId =
    pageData.meta.parent?.meta.type.toLowerCase() === 'offices.officepage'
      ? pageData.id
      : null

  const careers = await getChildPages(
    'careers.CareerPage',
    parentId,
    itemShowPerPage.CareerIndexPage,
    1,
    null,
    apiFieldsQuery.CareerPage
  )

  const offices = await getOffices({ fields: 'id,title', per_page: '500' })

  const createSelectOptions = (
    results,
    emptyLabel,
    labelKey = 'title',
    valueKey = 'id'
  ) =>
    results
      ? [{ value: '', label: emptyLabel }].concat(
          results.map((item) => ({
            value: item[valueKey],
            label: item[labelKey],
          }))
        )
      : []

  const searchFields = [
    ...(!pageData.meta.office_ancestor
      ? [
          {
            type: 'select',
            label: 'Find by office',
            name: 'location',
            isSearch: true,
            options: createSelectOptions(offices?.results, 'All Offices'),
          },
        ]
      : []),
    {
      type: 'select',
      label: 'Find By Department',
      name: 'department',
      options: createSelectOptions(
        pageData.departments,
        'All Departments',
        'name',
        'id'
      ),
    },
  ]

  return {
    office: pageData.meta.office_ancestor,
    jobs: {
      count: careers.meta.total_count,
      results: careers.items ? formatJobItems(careers.items) : [],
    },
    searchFields,
    parentId,
  }
}
CareerIndexPage.defaultProps = {}

export default CareerIndexPage
