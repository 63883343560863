import Button from '@global/Button'
import SearchInput from '@global/FormInput/SearchInput'
import { useDebouncedCallback } from 'use-debounce'
import * as S from './OfficeSearchForm.styled'

const OfficeSearchForm = ({ title, onSubmit }) => {
  const debounced = useDebouncedCallback((search) => {
    onSubmit({ search })
  }, 1000)

  return (
    <S.OfficeSearchForm>
      <S.OfficeSearchFormHeading>{title}</S.OfficeSearchFormHeading>
      <S.OfficeSearchFormBody>
        <SearchInput
          placeholder='Search by suburb, postcode or region'
          onChange={(e) => debounced(e.target.value)}
        />
        <Button type='submit' color='secondary' size='big'>
          Search
        </Button>
      </S.OfficeSearchFormBody>
    </S.OfficeSearchForm>
  )
}

OfficeSearchForm.defaultProps = {}

export default OfficeSearchForm
