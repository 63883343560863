import React, { useState } from 'react'
import HeroSection from '@global/Blocks/Hero'
import FullWidthText from '@global/Blocks/FullWidthText'
import WagtailBlocksSection from '@global/Blocks/WagtailBlocksSection'
import ArticleCard from '@global/ArticleCard'
import LoadmoreInfo from '@global/SearchResults/LoadmoreInfo'
import { Col, Container, Row, Section } from '@styles/global.styled'
import { getChildPages } from '@lib/api-services/cmsService'
import { getOffices } from '@lib/api-services/officesService'
import { apiFieldsQuery } from '@lib/variables'

const itemPerPage = 15

const formatPost = (data) => ({
  imgUrl: data.hero_image_thumb?.url,
  offices: data.offices?.map((office) => office.office.title),
  agents: data.agents?.map((agent) => agent.agent),
  link: { url: data.meta.path },
  linkLabel: 'read more',
  title: data.title,
  description: data.stripped_content,
})

const AwardIndexPage = ({ office, childOf, pageData, ...props }) => {
  const {
    title,
    hero_image,
    hero_text,
    preamble,
    hero_image_anchor,
    content_blocks,
  } = pageData
  const [queryParams, setQueryParams] = useState({
    offset: 0,
    limit: itemPerPage,
  })
  const [awards, setAwards] = useState({ ...props.awards })

  const getAwards = async (params) => {
    const modifiedParams = {
      ...queryParams,
      for_office: office?.id,
      offset: (params.page - 1) * itemPerPage,
      order:'-date_of_award'
    }

    setQueryParams(modifiedParams)

    try {
      const response = await getChildPages(
        'awards.AwardPage',
        childOf,
        itemPerPage,
        null,
        modifiedParams,
        apiFieldsQuery.AwardPage
      )

      return {
        count: response.meta.total_count,
        results: response.items?.map(formatPost),
      }
    } catch (error) {
      console.error('Failed to fetch awards:', error)
    }
  }

  return (
    <>
      <HeroSection
        title={title}
        bgImageUrl={hero_image?.url}
        titlePosition='bottom'
        bgPosition={hero_image_anchor}
      />
      <Section>
        <FullWidthText title={hero_text} para={preamble} />
      </Section>
      <Section>
        <Container>
          <Row gutter={15}>
            {awards.results?.map((post) => (
              <Col md={4} key={post.id}>
                <ArticleCard article={post} hideFavourite />
              </Col>
            ))}
          </Row>
          {awards?.count > 0 && (
            <LoadmoreInfo
              total={awards.count}
              numberShowing={
                (queryParams.offset / itemPerPage + 1) * itemPerPage
              }
              queryParams={queryParams}
              apiCall={getAwards}
              page={queryParams.offset / itemPerPage + 1}
              onSuccess={(data) => {
                setAwards((prevAwards) => ({
                  ...prevAwards,
                  results: prevAwards.results.concat(data),
                }))
              }}
            />
          )}
        </Container>
      </Section>
      <WagtailBlocksSection blocks={content_blocks} />
    </>
  )
}

AwardIndexPage.getInitialProps = async (ctx, pageData) => {
  const { query } = ctx
  const slug = query.slug
  delete query.slug

  const queryParams = { ...query, search: query.keyword }
  delete queryParams.keyword
  if (!queryParams.search) {
    queryParams.order = '-date_of_award'
  }

  const offices = await getOffices({ fields: 'id,title', per_page: '500' })

  const office = pageData.meta.office_ancestor
  let child_of = pageData.id
  if (office) {
    queryParams.for_office = office.id
    child_of = null
  }

  const awards = await getChildPages(
    'awards.AwardPage',
    child_of,
    itemPerPage,
    null,
    queryParams,
    apiFieldsQuery.AwardPage
  )

  return {
    office,
    slug,
    childOf: child_of,
    queryParams,
    awards: {
      ...awards,
      count: awards.meta.total_count,
      results: awards.items?.map(formatPost),
    },
  }
}

export default AwardIndexPage
