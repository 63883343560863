import { getProjectDetails } from '@lib/api-services/projectsService'
import SuburbApiDataFromName from '@components/PropertySearchResults/SuburbProfile/helpers'
import { getProperties } from '@lib/api-services/propertiesService'
import ProjectDetails from '@components/Projects/ProjectDetails'

const Project = (props) => {
  return <ProjectDetails {...props} />
}

Project.defaultProps = {
  enquireLabel: 'Enquire about this property',
}

Project.getInitialProps = async (ctx) => {
  const slug = ctx.query.slug
  const project = await getProjectDetails(slug)
  const properties = await getProperties({ parent_project_id: project.id })
  const suburbData = await SuburbApiDataFromName(project.address_suburb)

  return {
    project,
    properties,
    suburbData,
    location: project.location ? project.location.coordinates : null,
    propertiesViewMoreLink: {
      label: 'view more',
      url: `/project-properties?parent_project_id=${project.id}`,
    },
  }
}

export default Project
