import * as S from './InvestorHero.styled'
import { Col, Container, Row } from '@styles/global.styled'
import InvestorLandingForm from '../InvestorLandingForm'

const InvestorHero = (props) => {
  const { title, sub_heading, bullet_points, image, form, formId, disclaimer_text } = props

  const colonIndex = disclaimer_text && disclaimer_text.indexOf(":") || 0
  
  return (
    <S.InvestorHeroWrap>
      <Container className='investor-container'>
        <Row gutter={10} className='investor-row'>
          <Col md={6} lg={5} flex className='col-left'>
            <S.Title>{title}</S.Title>
            <S.SubTitle>{sub_heading}</S.SubTitle>
            {bullet_points && bullet_points.length > 0 && (
              <S.ListItems>
                {bullet_points.map((bullet, index) => (
                  <S.Item key={index}>{bullet.value.bullet_point}</S.Item>
                ))}
              </S.ListItems>
            )}
            <div id={formId} style={{display: 'none'}}/>
            {form.length > 0 && (
              <InvestorLandingForm formSlug={form[0].value.slug} />
            )}
            {disclaimer_text && (
              <S.Terms>
                <S.TermsBold>{disclaimer_text.substring(0, colonIndex + 1)}</S.TermsBold>{disclaimer_text.substring(colonIndex + 1)}
              </S.Terms>
            )}
          </Col>
          <Col md={6} lg={7} flex className='col-right'>
            <S.HeroImage src={image.meta.download_url} alt={''} />
          </Col>
        </Row>
      </Container>
    </S.InvestorHeroWrap>
  )
}

InvestorHero.defaultProps = {
  title: "Exclusive report on the Victorian property investment market.",
  sub_heading: "In this investor report you'll discover:",
  bullet_points: [
    {
      type: "bullet_point",
      value: {
        bullet_point: "Statewide property investor trends",
      },
      id: "",
    }
  ],
  image: {
    id: 0,
    meta: {
      type: "wagtailimages.Image",
      detail_url:"",
      download_url:"",
    },
    title: "",
  },
  disclaimer_text:
    "Terms & Conditions: By signing up for this Investor Advantage Report, you agree to receive the Investor Report via email and accept that Barry Plant may occasionally send you marketing and offers. You can unsubscribe at any time.",
  form: [],
}

export default InvestorHero
