import { DEVICES } from '@constants/global'
import { FONTS } from '@styles/fonts'
import { SectionTitle } from '@styles/global.styled'
import styled from 'styled-components'

export const RegionList = styled.div`
  margin-bottom: -64px;
`

export const RegionListTitle = styled(SectionTitle)`
  text-transform: unset;
  margin-bottom: 32px;
  font-family: ${FONTS.medium};

  @media ${DEVICES.mobile} {
    margin-bottom: 22px;
  }
`