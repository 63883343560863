import { Container } from '@styles/global.styled'
import * as S from './SuburbProfilesHero.styled'

const SuburbProfilesHero = ({ title, subTitle, content, isRegion }) => {
  return (
    <Container>
      <S.SuburbProfilesHero isRegion={isRegion}>
        <S.SuburbProfilesTitle>{title}</S.SuburbProfilesTitle>
        {subTitle && (
          <S.SuburbProfilesSubTitle>{subTitle}</S.SuburbProfilesSubTitle>
        )}
        {content && (
          <S.SuburbProfilesContent>{content}</S.SuburbProfilesContent>
        )}
      </S.SuburbProfilesHero>
    </Container>
  )
}

export default SuburbProfilesHero
