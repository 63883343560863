import styled, { css } from 'styled-components'
import { FONTS, textBase, textSM } from '@styles/fonts'
import { COLORS } from '@styles/colors'

export const Tag = styled.span`
  ${textSM};
  padding: 2px 10px;
  text-transform: uppercase;
  font-family: ${FONTS.bold};
  color: ${COLORS.secondary};
  background: ${COLORS.nature[95]};
  border-radius: 20px;
  letter-spacing: 0.8px;
  display: inline-block;

  ${({ size }) =>
    size &&
    css`
      ${size === 'medium' &&
      css`
        padding: 6px 14px;
        ${textBase};
        font-family: ${FONTS.regular};
        letter-spacing: 0.1px;
        text-transform: none;
      `}
    `}
`
