import * as S from './SuburbInfo.styled'
import { Container, Section } from '@styles/global.styled'

const SuburbInfo = ({ suburb, preamble }) => {
  return (
    <Section>
      <Container>
        <S.SuburbInfo>
          <S.SuburbInfoTitle>About {suburb}</S.SuburbInfoTitle>
          <S.SuburbInfoPreamble>{preamble}</S.SuburbInfoPreamble>
        </S.SuburbInfo>
      </Container>
    </Section>
  )
}

export default SuburbInfo
