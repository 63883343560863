import React, { useEffect, useState } from 'react'
import { getProjects } from '../lib/api-services/projectsService'
import { itemShowPerPage, domain, ApiKeys } from '../lib/variables'
import { NextSeo } from 'next-seo'
import { loadReCaptcha } from 'react-recaptcha-v3'
import { Container } from '@styles/global.styled'
import Hero from '@global/Blocks/Hero'
import ProjectSearchForm from '@components/Projects/ProjectSearchForm'
import WagtailBlocksSection from '@global/Blocks/WagtailBlocksSection'
import ProjectResults from '@components/Projects/ProjectResults'

const ProjectLandingIndex = (props) => {
  const { initProjects, meta, SEO, pageData, queryParams } = props
  const [page, setPage] = useState(1)
  const [projects, setProjects] = useState({
    ...initProjects,
  })

  const { content_blocks } = pageData
  const heroBlockData = content_blocks.find((it) => it.type === 'hero')
  const remainingBlocks = content_blocks.filter((it) => it.type !== 'hero')

  useEffect(() => {
    setProjects({ ...initProjects })
  }, [initProjects])

  useEffect(() => {
    if (!window.RECAPTCHA_INITIALIZED) {
      loadReCaptcha(ApiKeys.RECAPTCHA_KEY)
      window.RECAPTCHA_INITIALIZED = true
    }
  }, [])

  const per_page = itemShowPerPage.ProjectsSearchListPage
  const meta_from = page * per_page - per_page + 1
  const meta_to =
    page * per_page > meta.total_count ? meta.total_count : page * per_page
  const metaData = {
    total: meta.total_count || 0,
    from: meta_from,
    end: meta_to,
    searchString: 'properties in projects ',
    location: queryParams.address_suburb
      ? [
          {
            name: queryParams.address_suburb,
            type: 'suburb',
            url: `/project-properties/?address_suburb=${queryParams.address_suburb}`,
          },
        ]
      : [],
  }

  const getHeroTtile = () => {
    if (queryParams.status === 'Sold') {
      return 'Sold Projects'
    }

    return heroBlockData?.value?.heading ?? 'Current Projects'
  }

  return (
    <React.Fragment>
      <NextSeo {...SEO} />
      <Hero
        title={getHeroTtile()}
        bgImageUrl={heroBlockData?.value?.background_image}
        subTitle={heroBlockData.value.sub_heading}
        titlePosition='bottom'
        bgPosition={heroBlockData.value.image_position}
      />
      <Container>
        <ProjectSearchForm />
        <ProjectResults
          projects={projects}
          setProjects={setProjects}
          page={page}
          setPage={setPage}
          metaData={metaData}
          getProjects={getProjects}
          queryParams={queryParams}
        />
      </Container>
      <WagtailBlocksSection blocks={remainingBlocks} />
    </React.Fragment>
  )
}

ProjectLandingIndex.getInitialProps = async (ctx, pageData) => {
  let SEO = {
    canonical: `${domain}${ctx.asPath}`,
  }
  const view = ctx.query.view ?? 'grid'

  const params = {
    address_suburb: ctx.query.address_suburb ?? '',
    per_page: view === 'grid' ? itemShowPerPage.ProjectsSearchListPage : 500,
    page: 1,
    status: ctx.query.status ?? '',
  }
  const projects = await getProjects(params)

  // If map view - Filter projects have location
  if (view === 'map') {
    projects.results = projects.results.filter((it) => it.location?.coordinates)
    projects.count = projects.results.length
  }

  return {
    SEO: SEO,
    initProjects: projects,
    meta: {
      total_count: projects.count,
    },
    pageData,
    queryParams: { ...params, view },
  }
}

export default ProjectLandingIndex
